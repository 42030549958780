import * as React from 'react';
import { connect } from 'react-redux';

import { USER_URLS } from '../../utilities/urls';
import { IStore } from '../../Store';
import { IUserRole } from '../../Store/UserInfo';
import { loadingStartedAction, loadingFinishedAction } from '../../Store/CommonStore';
import Modal from '../../components/controls/Modal';
import NewUserForm from './NewUserForm';
import './index.css';
import { request } from '../../utilities';

interface IStateProps {
    token: string
}

const mapStateToProps = ({ user }: IStore): IStateProps => ({
    token: user.token!
})

interface IDispatchProps {
    loadingStarted: (payload?: any) => void,
    loadingFinished: (payload?: any) => void
}

const mapDispatchToProps: IDispatchProps = {
    loadingStarted: loadingStartedAction,
    loadingFinished: loadingFinishedAction
}

interface IUser {
    login: string,
    affilate?: string,
    role: IUserRole
}

interface IState {
    users: IUser[],
    isCreateModalOpen: boolean
}

type IProps = IStateProps & IDispatchProps;

class UsersPage extends React.Component<IProps, IState> {
    public state: IState = {
        users: [],
        isCreateModalOpen: false
    }

    private _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        this.props.loadingStarted()

        request(this.props.token).get(USER_URLS.GET)
            .then(({ data }) => {
                if(this._isMounted) {
                    this.setState({ users: data })
                }
            }).finally(() => {
                if(this._isMounted) {
                    this.props.loadingFinished();
                }
            })
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.loadingFinished();
    }

    render() {
        const { users, isCreateModalOpen } = this.state;

        return(
            <div className="users-page page-content">
                <ul>
                { users.map(({ login, affilate, role }) =>
                    <li key={ login }>{ login } — { role } { affilate }</li> 
                ) }
                </ul>

                <button
                    className="main"
                    onClick={ () => this.setState({ isCreateModalOpen: true }) }
                >Добавить пользователя</button>

                <Modal
                    className="new-user-modal"
                    open={ isCreateModalOpen }
                    onClose={ () => this.setState({ isCreateModalOpen: false }) }
                >
                    <NewUserForm { ...this.props } />
                </Modal>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersPage);