import * as React from 'react';
import moment from 'moment';

import Datepicker from '../../../components/controls/Datepicker';
import Dropdown, { IOption } from '../../../components/controls/Dropdown';
import { getFullName } from '../../../utilities';
import { DOCTOR_URLS, SEANCE_URLS } from '../../../utilities/urls';
import Input from '../../../components/controls/Input';
import Switch from '../../../components/controls/Switch';
import { request } from '../../../utilities';
import './index.css';

interface IProps {
    token: string,
    onSubmit: () => void,
    loadingStarted: () => void,
    loadingFinished: () => void,
    initialDate?: moment.Moment
}

interface ITimeState {
    startHour: number,
    startMinute: number,
    endHour: number,
    endMinute: number,
    brakeStartHour: number,
    brakeStartMinute: number,
    brakeEndHour: number,
    brakeEndMinute: number,
    length: number,
}

interface IState extends ITimeState {
    date?: moment.Moment,
    doctorKey?: number,

    haveBrake: boolean,
    doctorOptions: IOption[],
    canSend: boolean
}

interface IGenerateScheduleRequestBody {
    doctorId: number,        // id врача
    date: string,            // дата (MM/DD/YYYY)
    startTime: string,       // время начала приёма (hh:mm)
    breakStartTime?: string, // время начала перерыва (hh:mm)
    breakEndTime?: string,   // время окончания перерыва (hh:mm)
    endTime: string,         // время окончания приёма (hh:mm)
    length: number           // длительность приёма (мин)
}

class ScheduleGenerator extends React.Component<IProps, IState> {
    public state: IState = {
        startHour: 8,
        startMinute: 0,
        endHour: 20,
        endMinute: 0,
        brakeStartHour: 13,
        brakeStartMinute: 0,
        brakeEndHour: 14,
        brakeEndMinute: 0,
        length: 20,

        haveBrake: false,
        doctorOptions: [],
        canSend: false
    }

    private _isMounted = false;

    componentDidMount() {
        this._isMounted = true;

        const { loadingStarted, loadingFinished, token, initialDate } = this.props;

        loadingStarted();
        this.setState({ date: initialDate });

        request(token).get(DOCTOR_URLS.GET)
            .then(({ data }) => {
                if(this._isMounted) {
                    this.setState({ doctorOptions: mapResponseDataToDoctorOptions(data) });
                }
            }).finally(() => {
                if(this._isMounted) {
                    loadingFinished();
                }
            })
    }

    componentWillMount() {
        this._isMounted = false;
        this.props.loadingFinished();
    }

    componentDidUpdate(prevProps: any, prevState: IState) {
        const { date, doctorKey } = this.state;

        if(
            prevState.date !== date ||
            prevState.doctorKey !== doctorKey
        ) {
            this.setState({
                canSend: date !== undefined && doctorKey !== undefined
            })
        }        
    }

    sendGenerateRequest = () => {
        const {
                doctorKey, date,
                startHour, startMinute,
                haveBrake,
                brakeStartHour, brakeStartMinute,
                brakeEndHour, brakeEndMinute,
                endHour, endMinute,
                length
            } = this.state;

        const startTime = moment(date).hour(startHour).minute(startMinute).format("HH:mm"),
            endTime = moment(date).hour(endHour).minute(endMinute).format("HH:mm");

        let breakStartTime: string | undefined, breakEndTime: string | undefined;

        if(haveBrake) {
            breakStartTime = moment(date).hour(brakeStartHour).minute(brakeStartMinute).format("HH:mm");
            breakEndTime = moment(date).hour(brakeEndHour).minute(brakeEndMinute).format("HH:mm");
        }

        const requestData: IGenerateScheduleRequestBody = {
                doctorId: doctorKey!,
                date: date!.format("YYYY/MM/DD"),
                startTime,
                breakStartTime,
                breakEndTime,
                endTime,
                length
            }, { token, onSubmit } = this.props;

        request(token).post(SEANCE_URLS.GENERATE_SCHEDULE, requestData)
            .then(onSubmit);
    }

    handleTimeChange = <T extends keyof ITimeState>(fieldName: T) =>
        (value: string) =>
            this.setState<T>({ [fieldName]: parseInt(value) || 0 });

    render() {
        const {
            date, doctorOptions, doctorKey,
            startHour, startMinute, endHour, endMinute,
            haveBrake,
            brakeStartHour, brakeStartMinute, brakeEndHour, brakeEndMinute,
            length,
            canSend
        } = this.state;

        return(
            <div className="schedule-generator">
                <h2>Генератор расписания</h2>

                <div className="first block">
                    <Datepicker
                        label="Дата"
                        value={ date }
                        onChange={ (date) => this.setState({ date }) }
                    />

                    <Dropdown
                        label="Врач"
                        placeholder="Выберите врача"
                        data={ doctorOptions }
                        selectedKey={ doctorKey }
                        onChange={ (doctorKey) => this.setState({ doctorKey }) }
                    />
                </div>

                <div className="block">
                    <div className="block-with-label">
                        <label className="block-label time">Время начала работы</label>  
                        <div className="block">
                            <Input
                                className="work-time-input hour"
                                value={ startHour }
                                onChange={ this.handleTimeChange("startHour") }
                                valid={ startHour >=0 && startHour <= 23 }
                                numeric
                            />

                            <Input
                                className="work-time-input minute"
                                value={ startMinute }
                                onChange={ this.handleTimeChange("startMinute") }
                                valid={ startMinute >=0 && startMinute <= 59 }
                                numeric
                            />
                        </div>
                    </div>

                    <div className="block-with-label">
                        <label className="block-label time">Время окончания работы</label>  
                        <div className="block">
                            <Input
                                className="work-time-input hour"
                                value={ endHour }
                                onChange={ this.handleTimeChange("endHour") }
                                valid={ endHour >=0 && endHour <= 23 }
                                numeric
                            />

                            <Input
                                className="work-time-input minute"
                                value={ endMinute }
                                onChange={ this.handleTimeChange("endMinute") }
                                valid={ endMinute >=0 && endMinute <= 59 }
                                numeric
                            />
                        </div>
                    </div>
                </div>

                <div className="block">
                    <label className="block-label">Перерыв</label>
                    <Switch
                        value={ haveBrake }
                        onChange={ (haveBrake) => this.setState({ haveBrake }) }
                    />
                </div>

                {
                    haveBrake &&
                    <div className="block">
                        <div className="block-with-label">
                            <label className="block-label time">Время начала перерыва</label>  
                            <div className="block">
                                <Input
                                    className="work-time-input hour"
                                    value={ brakeStartHour }
                                    onChange={ this.handleTimeChange("brakeStartHour") }
                                    valid={ brakeStartHour >=0 && brakeStartHour <= 23 }
                                    numeric
                                />

                                <Input
                                    className="work-time-input minute"
                                    value={ brakeStartMinute }
                                    onChange={ this.handleTimeChange("brakeStartMinute") }
                                    valid={ brakeStartMinute >=0 && brakeStartMinute <= 59 }
                                    numeric
                                />
                            </div>
                        </div>

                        <div className="block-with-label">
                            <label className="block-label time">Время окончания перерыва</label>  
                            <div className="block">
                                <Input
                                    className="work-time-input hour"
                                    value={ brakeEndHour }
                                    onChange={ this.handleTimeChange("brakeEndHour") }
                                    valid={ brakeEndHour >=0 && startHour <= 23 }
                                    numeric
                                />
                                <Input
                                    className="work-time-input minute"
                                    value={ brakeEndMinute }
                                    onChange={ this.handleTimeChange("brakeEndMinute") }
                                    valid={ brakeEndMinute >=0 && brakeEndMinute <= 59 }
                                    numeric
                                />
                            </div>
                        </div>
                    </div>
                }

                <label className="block-label time">Длительность приёма</label>
                <Input
                    className="work-time-input minute"
                    value={ length }
                    onChange={ this.handleTimeChange("length") }
                    valid={ length >0 }
                    numeric
                />

                <button
                    className="main"
                    onClick={ this.sendGenerateRequest }
                    disabled={ !canSend }
                >Создать расписание</button>
            </div>
        )
    }
}

export default ScheduleGenerator;

function mapResponseDataToDoctorOptions(doctors: any): IOption[] {
    try {
        return doctors.map((doctor: any) => ({
            key: doctor.id,
            value: doctor.id,
            text: getFullName(doctor)
        }));
    } catch(error) {
        console.error(error.message);
        return []
    }
}