import { takeEvery, call, put } from 'redux-saga/effects';

import { IAction } from '../actions';
import { USER_ACTIONS } from '.';
import { COMMON_ACTIONS } from '../CommonStore';
import { AUTH_URLS } from '../../utilities/urls';
import { request } from '../../utilities';
import { LOCAL_STORAGE_USER_INFO } from '../../utilities/constants';
import { getUserToken } from '../../utilities';

export default {
    *dispatchAsync() {
        yield takeEvery(USER_ACTIONS.LOGIN, login);
        yield takeEvery(USER_ACTIONS.VERIFY_TOKEN, verifyToken);
    }
}

function* login({ type, payload }: IAction) {
    try {
        yield put({ type: COMMON_ACTIONS.LOADING_STARTED });
        const response = yield call(() => request().post(AUTH_URLS.LOGIN, payload));

        yield put({ type: USER_ACTIONS.SET_USER_INFO, payload: response.data });
        localStorage.setItem(LOCAL_STORAGE_USER_INFO, JSON.stringify(response.data));
    } catch(error) {
        console.error(error.message);
        yield put({ type: COMMON_ACTIONS.SET_ALERT_TEXT, payload: error.response.data });
    } finally {
        yield put({ type: COMMON_ACTIONS.LOADING_FINISHED });
    }
}

function* verifyToken() {
    try {
        const token = getUserToken(),
            response = yield call(() => request(token).post(AUTH_URLS.VERIFY_TOKEN));

        if(response.status == 200) {
            yield put({
                type: USER_ACTIONS.SET_USER_INFO,
                payload: { ...response.data }
            });

            return;
        }

        yield put({ type: USER_ACTIONS.LOGOUT });   
    } catch(error) {
        console.error(error.message);
        yield put({ type: USER_ACTIONS.LOGOUT });
        yield put({ type: COMMON_ACTIONS.SET_ALERT_TEXT, payload: error.response.data });
    }
}