import * as React from 'react';

import './index.css';

interface IProps {
    onTrigger?: () => void,
    isOpen: boolean,
    modalRef?: (ref: HTMLDivElement | null) => void
}

const ActionMenu: React.FC<IProps> =
    ({ children, onTrigger, isOpen, modalRef }) => 
        <div className="action-menu">
            <div
                className="clickable trigger"
                onClick={ onTrigger }
            />

            { isOpen &&
                <div className="menu-popup" ref={ modalRef }>
                    { children }
                </div>
            }
        </div>



export default ActionMenu;