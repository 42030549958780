import * as React from 'react';
import classnames from 'classnames';

import './index.css';
import Modal from '../Modal';

interface IProps {
    trigger: (onClick: () => void) => JSX.Element,
    onAccept: () => void,
    acceptButtonClassName?: "danger" | "warning",
    text: string
}

interface IState {
    isOpen: boolean
}

class ConfirmationModal extends React.Component<IProps, IState> {
    public state: IState = {
        isOpen: false
    }

    setIsOpen = (isOpen: boolean) => () =>
        this.setState({ isOpen })

    handleAccept = () => {
        this.props.onAccept();
        this.setState({ isOpen: false });
    }

    render() {
        const { trigger, text, acceptButtonClassName } = this.props,
            { isOpen } = this.state;

        return(
            <React.Fragment>
                <Modal
                    open={ isOpen }
                    className="confirmation-modal"
                    onClose={ this.setIsOpen(false) }
                >
                    <p>{ text }</p>
                    <button
                        className={ classnames(
                            "main",
                            acceptButtonClassName
                        ) }
                        onClick={ this.handleAccept }
                    >Подтвердить</button>
                    <button
                        className="secondary"
                        onClick={ this.setIsOpen(false) }
                    >Отменить</button>
                </Modal>
                { trigger(this.setIsOpen(true)) }
            </React.Fragment>
        )
    }
}

export default ConfirmationModal;