import * as React from 'react';

import Input from '../../components/controls/Input2';
import { DOCTOR_URLS } from '../../utilities/urls';
import { request } from '../../utilities';

interface IProps {
    token: string,
    loadingStarted: (payload?: any) => void,
    loadingFinished: (payload?: any) => void,
    onCreate: () => void
}

interface IState {
    lastName: string,
	firstName: string,
	patronymic: string,
	info: string,
    canCreate: boolean
}

class NewDoctorForm extends React.Component<IProps, IState> {
    public state: IState = {
        lastName: "",
        firstName: "",
        patronymic: "",
        info: "",
        canCreate: false
    }

    private _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.loadingFinished();
    }

    componentDidUpdate(prevProps: any, prevState: IState) {
        const { lastName, firstName, patronymic } = this.state;

        if(
            prevState.lastName !== lastName ||
            prevState.firstName !== firstName ||
            prevState.patronymic !== patronymic
        ) {
            this.setState({
                canCreate: lastName !== "" && firstName !== "" && patronymic !== ""
            })
        }
    }

    createUser = () => {
        const { lastName, firstName, patronymic, info } = this.state;

        this.props.loadingStarted();
        
        request(this.props.token).post(DOCTOR_URLS.CREATE,
            {
                lastName,
                firstName,
                patronymic,
                info
            }
        )
            .then(({ data }) => {
                console.log(data);
                if(this._isMounted) {
                    this.props.onCreate();
                }
            }).finally(() => {
                if(this._isMounted) {
                    this.props.loadingFinished();
                }
            })
    }

    render() {
        const { lastName, firstName, patronymic, info, canCreate } = this.state;

        return(
            <div className="new-user-form">
                <Input
                    label="Фамилия"
                    placeholder="Иванов"
                    value={ lastName }
                    onChange={ (lastName) => this.setState({ lastName }) } 
                    required
                />

                <Input
                    label="Имя"
                    placeholder="Иван"
                    value={ firstName }
                    onChange={ (firstName) => this.setState({ firstName }) } 
                    required
                />

                <Input
                    label="Отчество"
                    placeholder="Иванович"
                    value={ patronymic }
                    onChange={ (patronymic) => this.setState({ patronymic }) } 
                    required
                />

                <Input
                    label="Информация"
                    placeholder="Доктор наук"
                    value={ info }
                    onChange={ (info) => this.setState({ info }) } 
                />

                <button 
                    className="main"
                    onClick={ this.createUser }
                    disabled={ !canCreate }
                >Добавить</button>
            </div>
        )
    }
}

export default NewDoctorForm;