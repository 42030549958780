import React, { FC } from "react";

const OutpatientCardInfo: FC = () => {
    return (
        <div className="outpatient-card-info">
            <p>
                Вам следует прийти в регистратуру{" "}
                <b>не позднее, чем за 30 минут</b> до начала приёма для того,
                чтобы успеть <b>оформить амбулаторную карту</b>.
            </p>
            <p>
                Для оформления амбулаторной карты <b>при каждом визите</b>{" "}
                необходимо иметь при себе <b>паспорт</b>, <b>полис ОМС</b> и{" "}
                <b>СНИЛС</b> - <b>оригиналы документов</b>.
            </p>
        </div>
    );
};

export default OutpatientCardInfo;
