import * as React from "react";
import OutpatientCardInfo from "./OutpatientCardInfo";

function RegInfo({ onClose }: { onClose: () => void }) {
    return (
        <div className="reg-info">
            <div className="header">
                <h3>Уважаемые посетители сайта ГБУЗ НПЦ им. Соловьева ДЗМ!</h3>
                <div className="clickable close-button" onClick={onClose} />
            </div>
            <p>
                У Вас есть возможность записаться на прием к врачу. Запись
                осуществляется предварительно на ближайшие 49 дней, если на
                следующий день – не позже 14-00 текущего дня при наличии
                свободных талонов. Запись происходит автоматически.
            </p>
            <OutpatientCardInfo />
        </div>
    );
}

export default RegInfo;
