import * as React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { IUserRole, logoutAction } from "../../Store/UserInfo";
import { APP_PAGES } from "../../utilities/urls";
import { IStore } from "../../Store";
import "./index.css";

interface IStateProps {
    role?: string;
    affilate?: string;
}

const mapStateToProps = ({ user }: IStore): IStateProps => ({
    role: user.role,
    affilate: user.affilate,
});

interface IDispatchProps {
    logout: (payload?: any) => void;
}

const mapDispatchToProps: IDispatchProps = {
    logout: logoutAction,
};

type IProps = IStateProps & IDispatchProps;

class NavBar extends React.Component<IProps> {
    renderUserInfo = () => {
        const { affilate, role } = this.props;

        let roleLabel = <React.Fragment />;

        switch (role) {
            case IUserRole.ADMIN:
                roleLabel = <h3>Администратор</h3>;
                break;

            case IUserRole.SUPERADMIN:
                roleLabel = <h3>Системный администратор</h3>;
                break;
        }

        return (
            <div className="user-info">
                {roleLabel}
                {affilate && <h2>{affilate}</h2>}
            </div>
        );
    };

    renderAllowedLinks = () => {
        const { role } = this.props;

        switch (role) {
            case IUserRole.SUPERADMIN:
                return [
                    createNavLink(APP_PAGES.AFFILATES, "Филиалы"),
                    createNavLink(APP_PAGES.USERS, "Пользователи"),
                    createNavLink(APP_PAGES.PATIENT_ACTIONS, "Действия"),
                ];

            case IUserRole.ADMIN:
                return [
                    createNavLink(APP_PAGES.DOCTORS, "Врачи"),
                    createNavLink(APP_PAGES.SCHEDULE, "Расписание"),
                    createNavLink(APP_PAGES.PATIENTS, "Пациенты"),
                    createNavLink(APP_PAGES.PATIENT_ACTIONS, "Действия"),
                ];

            default:
                return null;
        }
    };

    render() {
        const { logout } = this.props;

        return (
            <React.Fragment>
                <div className="nav-bar">
                    {this.renderUserInfo()}

                    <div className="menu">
                        <NavLink
                            exact
                            to={APP_PAGES.HOME}
                            className="nav-link"
                            activeClassName="active"
                        >
                            Запись
                        </NavLink>
                        {this.renderAllowedLinks()}
                    </div>

                    <div className="logout">
                        <button className="clickable main" onClick={logout}>
                            Выход
                        </button>
                    </div>
                </div>

                <div className="nav-bar-placeholder" />
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);

function createNavLink(path: string, text: string) {
    return (
        <NavLink
            to={path}
            key={path}
            className="nav-link"
            activeClassName="active"
        >
            {text}
        </NavLink>
    );
}
