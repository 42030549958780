import React, { FC } from "react";

import { PhoneList } from "./PhoneList";

const WarningWindow: FC = () => (
    <div className="warning-window">
        <p className="title">
            При отсутствии постоянной Московской регистрации услуги
            предоставляются на платной основе.
        </p>

        <p className="text">
            Для записи на платные услуги просим вас записываться через
            регистратуру:
        </p>

        <PhoneList isPaid={true} />
    </div>
);

export default WarningWindow;
