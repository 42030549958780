import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import { IStore } from "../Store";
import { APP_PAGES } from "../utilities/urls";
import RegForm from "../pages/RegForm";
import AppointmentInfo from "../pages/RegForm/AppointmentInfo";
import LoginForm from "../pages/LoginForm";
import AffilatesPage from "../pages/AffilatesPage";
import UsersPage from "../pages/UsersPage";
import SchedulePage from "../pages/SchedulePage";
import DoctorsPage from "../pages/DoctorsPage";
import PatientsPage from "../pages/PatientsPage";
import DevelopmentPlayground from "../pages/DevelopmentPlayground";
import NavBar from "../components/NavBar";
import NotFound from "../pages/NotFound";
import Loader from "../components/controls/Loader";
import AlertModal from "../components/controls/AlertModal";
import { verifyTokenAction, IUserRole } from "../Store/UserInfo";
import { setAlertTextAction } from "../Store/CommonStore";
import { getUserToken } from "../utilities";
import { PatientActionsPage } from "../pages/PatientActionsPage";
import "./index.css";

interface IStateProps {
    role?: IUserRole;
    isLoading: boolean;
    alertText?: string;
}

const mapStateToProps = ({ user, common }: IStore): IStateProps => ({
    role: user.role,
    isLoading: common.loadingsCount != 0,
    alertText: common.alertText,
});

interface IDispatchProps {
    verifyToken: (payload?: any) => void;
    setAlertText: (payload: string | undefined) => void;
}

const mapDispatchToProps: IDispatchProps = {
    verifyToken: verifyTokenAction,
    setAlertText: setAlertTextAction,
};

type IProps = IStateProps & IDispatchProps;

class App extends React.Component<IProps> {
    componentDidMount() {
        if (getUserToken()) {
            this.props.verifyToken();
        }
    }

    getAllowedRoutes = (role?: IUserRole) => {
        switch (role) {
            case IUserRole.SUPERADMIN:
                return [
                    createRoute(APP_PAGES.USERS, UsersPage),
                    createRoute(APP_PAGES.AFFILATES, AffilatesPage),
                ];

            case IUserRole.ADMIN:
                return [
                    createRoute(APP_PAGES.DOCTORS, DoctorsPage),
                    createRoute(APP_PAGES.SCHEDULE, SchedulePage),
                    createRoute(APP_PAGES.PATIENTS, PatientsPage),
                ];

            default:
                return null;
        }
    };

    render() {
        const { role, isLoading, alertText, setAlertText } = this.props;

        return (
            <div className="app">
                {role && <NavBar />}

                <Switch>
                    <Route path="/dev" component={DevelopmentPlayground} />
                    <Route exact path={APP_PAGES.HOME} component={RegForm} />
                    <Route path={APP_PAGES.LOGIN} component={LoginForm} />
                    <Route
                        path={APP_PAGES.APPOINTMENT_INFO}
                        component={AppointmentInfo}
                    />
                    <Route
                        path={APP_PAGES.PATIENT_ACTIONS}
                        component={PatientActionsPage}
                    />
                    {this.getAllowedRoutes(role)}
                    <Route component={NotFound} />
                </Switch>

                {
                    <AlertModal
                        text={alertText}
                        onClose={() => setAlertText(undefined)}
                    />
                }
                {isLoading && <Loader />}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

function createRoute(path: string, component: React.ComponentType) {
    return <Route key={path} path={path} component={component} />;
}
