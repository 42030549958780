import React, { FC } from "react";
import block from "bem-cn";

import { getFullName } from "../../utilities";
import { usePatientActions } from "./usePatientActions";
import Loader from "../../components/controls/Loader";
import "./PatientActionsPage.scss";
import { formatDate } from "./utils";

const pageCn = block("patient-actions-page");
const tableCn = block("patient-actions-table");

const PatientActionsPage: FC = () => {
    const {
        patientSearch,
        setPatientSearch,
        doctorSearch,
        setDoctorSearch,
        patientActions,
        isLoading,
        isNotFound,
    } = usePatientActions();

    return (
        <div className={pageCn()}>
            <h1 className={pageCn("header")}>Действия пациентов</h1>

            <label className={pageCn("search-label")}>
                Поиск по ФИО пациента
                <input
                    className={pageCn("search-input")}
                    type="text"
                    value={patientSearch}
                    onChange={({ currentTarget }) =>
                        setPatientSearch(currentTarget.value)
                    }
                />
            </label>

            <label className={pageCn("search-label")}>
                Поиск по ФИО врача
                <input
                    className={pageCn("search-input")}
                    type="text"
                    value={doctorSearch}
                    onChange={({ currentTarget }) =>
                        setDoctorSearch(currentTarget.value)
                    }
                />
            </label>

            {isLoading && <Loader />}

            {isNotFound && <div>Пациенты не найдены</div>}

            {!isLoading && patientActions.length !== 0 && (
                <table className={tableCn()}>
                    <thead className={tableCn("head")}>
                        <tr className={tableCn("row", { head: true })}>
                            <th className={tableCn("cell")}>Дата действия</th>
                            <th className={tableCn("cell")}>Действие</th>
                            <th className={tableCn("cell")}>Пациент</th>
                            <th className={tableCn("cell")}>Дата сеанса</th>
                            <th className={tableCn("cell")}>Врач</th>
                        </tr>
                    </thead>

                    <tbody className={tableCn("body")}>
                        {patientActions.map((action) => (
                            <tr key={action.id} className={tableCn("row")}>
                                <td className={tableCn("cell")}>
                                    {formatDate(action.createdAt)}
                                </td>

                                <td className={tableCn("cell")}>
                                    {action.type === "0" ? (
                                        <span
                                            className={tableCn("appointment")}
                                        >
                                            Запись на приём
                                        </span>
                                    ) : (
                                        <span className={tableCn("cancel")}>
                                            Отмена записи
                                        </span>
                                    )}
                                </td>

                                <td className={tableCn("cell")}>
                                    {getFullName(action.patient)}
                                    {` (тел. ${action.patient.phone})`}
                                </td>

                                <td className={tableCn("cell")}>
                                    {action.seanceDate} {action.seanceTime}
                                </td>

                                <td className={tableCn("cell")}>
                                    {action.doctorName}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default PatientActionsPage;
