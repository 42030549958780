import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { sortBy } from 'lodash';

import { SEANCE_URLS } from '../../utilities/urls';
import { getFullName, request } from '../../utilities';
import Datepicker from '../../components/controls/Datepicker';
import { IStore } from '../../Store';
import { loadingStartedAction, loadingFinishedAction, setAlertTextAction } from '../../Store/CommonStore';
import Modal from '../../components/controls/Modal';
import ScheduleGenerator from './ScheduleGenerator';
import ActionMenu from '../../components/controls/ActionMenu';
import ConfirmationModal from '../../components/controls/ConfirmationModal';
import PatientCard from '../PatientsPage/PatientCard';
import './index.css';

interface IStateProps {
    token: string
}

const mapStateToProps = ({ user }: IStore): IStateProps => ({
    token: user.token!
})

interface IDispatchProps {
    loadingStarted: (payload?: any) => void,
    loadingFinished: (payload?: any) => void,
    setAlertText: (payload: string | undefined) => void
}

const mapDispatchToProps: IDispatchProps = {
    loadingStarted: loadingStartedAction,
    loadingFinished: loadingFinishedAction,
    setAlertText: setAlertTextAction
}

enum SortableKeys {
    Doctor = "doctor",
    Patient = "patient",
    Date = "date"
}

export interface ISeance {
    id: number,
    date: moment.Moment,
    doctorName: string,
    patientName?: string,
    patientId?: number
}

interface IState {
    date: moment.Moment,
    seances: ISeance[],
    sortKey?: SortableKeys,
    isGeneratorModalOpen: boolean,
    activePopupId?: number,
    activePatientId?: number
}

type IProps = IStateProps & IDispatchProps;

class SchedulePage extends React.Component<IProps, IState> {
    public state: IState = {
        date: moment().startOf("date"),
        seances: [],
        isGeneratorModalOpen: false,
    }

    private _isMounted = false;
    public actionMenuRef: HTMLDivElement | null = null;

    componentDidMount() {
        this._isMounted = true;
        const { date } = this.state;
        
        this.requestSchedule(date);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.loadingFinished();
    }

    componentDidUpdate(prevProps: any, prevState: IState) {
        const { date } = this.state;

        if(prevState.date !== date) {
            this.requestSchedule(date);
        }
    }

    requestSchedule = (date: moment.Moment) => {
        const { loadingStarted, loadingFinished, token, setAlertText } = this.props;
        
        loadingStarted();

        request(token).post(SEANCE_URLS.GET, {
            startDate: date.format("MM/DD/YYYY"),
            endDate: moment(date).add(1, "day").format("MM/DD/YYYY")
        })
            .then(({ data }) => {
                if(this._isMounted) {
                    this.setState({ seances: mapResponseDataToSeances(data) });
                }
            }).catch(({ response: { data } }) => {
                if(this._isMounted) {
                    setAlertText(data);
                }
            }).finally(() => {
                if(this._isMounted) {
                    loadingFinished();
                }
            })
    }
    
    deleteSeance = (id: number) => () => {
        const { loadingStarted, loadingFinished, token, setAlertText } = this.props,
            { date } = this.state;

        loadingStarted();

        request(token).delete(SEANCE_URLS.DELETE_SEANCE(id))
            .then(() => {
                if(this._isMounted) {
                    this.requestSchedule(date);
                }
            }).catch(({ response: { data } }) => {
                if(this._isMounted) {
                    setAlertText(data);
                }
            }).finally(() => {
                if(this._isMounted) {
                    loadingFinished();
                }
            })
    }

    removePatient = (id: number) => () => {
        const { loadingStarted, loadingFinished, token, setAlertText } = this.props,
            { date } = this.state;

        loadingStarted();

        request(token).put(SEANCE_URLS.REMOVE_PATIENT(id))
            .then(() => {
                if(this._isMounted) {
                    this.requestSchedule(date);
                }
            }).catch(({ response: { data } }) => {
                if(this._isMounted) {
                    setAlertText(data);
                }
            }).finally(() => {
                if(this._isMounted) {
                    loadingFinished();
                }
            })
    }

    closePopup = (event: MouseEvent) => {
        if(this.actionMenuRef && !this.actionMenuRef.contains(event.target as Node)) {
            this.setState({ activePopupId: undefined });
            document.removeEventListener("click", this.closePopup);
        }
    }

    handlePopupTrigger = (id: number) => () => {
        if(this.state.activePopupId === undefined) {
            document.addEventListener("click", this.closePopup);
        }
        
        if(this.state.activePopupId === id) {
            document.removeEventListener("click", this.closePopup);
        }

        if(this.state.activePopupId !== id) {
            document.removeEventListener("click", this.closePopup);
            document.addEventListener("click", this.closePopup);            
        }

        this.setState({
            activePopupId: id !== this.state.activePopupId ? id : undefined
        })
    }

    handleScheduleSubmit = () => {
        this.setState({
            isGeneratorModalOpen: false
        });

        this.requestSchedule(this.state.date);
    }

    switchGeneratorModalState = (isGeneratorModalOpen: boolean) =>
        () => this.setState({ isGeneratorModalOpen })

    handlePatientClick = (patientId?: number) => () =>
        this.setState({ activePatientId: patientId })

    sortSeancesBy = (sortKey: SortableKeys) => () => {
        this.setState(({ seances }) => {
            switch(sortKey) {
                case SortableKeys.Doctor:
                    return {
                        seances: sortBy(seances, (seance) => seance.doctorName),
                        sortKey
                    }

                case SortableKeys.Patient:
                    return {
                        seances: sortBy(seances, (seance) => seance.patientName),
                        sortKey
                    }

                case SortableKeys.Date:
                    return {
                        seances: sortBy(seances, (seance) => seance.date.valueOf()),
                        sortKey
                    }

                default:
                    return { seances }
            }
        })
    }

    renderSortableTableHeaderCell = ({ key, text }: { key: SortableKeys, text: string }) => 
        <div
            key={ key }
            className={ classnames(
                "clickable table-cell",
                { "sorted": key == this.state.sortKey }
            ) }
            onClick={ this.sortSeancesBy(key) }
        >{ text }</div>

    render() {
        const { seances, date, isGeneratorModalOpen, activePopupId, activePatientId } = this.state

        return(
            <div className="schedule-page page-content">
                <h1>Расписание</h1>
                <div className="menu">
                    <Datepicker
                        label="Дата"
                        value={ date }
                        onChange={ (date) => this.setState({ date }) }
                    />

                    <button
                        className="main"
                        onClick={ this.switchGeneratorModalState(true) }
                    >Генератор расписания</button>
                    <Modal
                        open={ isGeneratorModalOpen }
                        onClose={ this.switchGeneratorModalState(false) }
                    >
                        <ScheduleGenerator
                            onSubmit={ this.handleScheduleSubmit }
                            { ...this.props }
                            initialDate={ date }
                        />
                    </Modal>

                    { activePatientId &&
                        <Modal
                            onClose={ this.handlePatientClick() }
                            open
                        >
                            <PatientCard patientId={ activePatientId } />
                        </Modal>
                    }
                </div>
            {
                seances.length !== 0 &&
                <div className="schedule-table">
                    <div className="table-row header">
                        {
                            [
                                { key: SortableKeys.Date, text: "Время" },
                                { key: SortableKeys.Doctor, text: "Врач" },
                                { key: SortableKeys.Patient, text: "Пациент" }
                            ].map(this.renderSortableTableHeaderCell)
                        }
                        <div className="table-cell" />
                    </div>
                {
                    seances.map(({ id, date, doctorName, patientName, patientId }) =>
                        <div key={ id } className="table-row">
                            <div className="table-cell">
                                { date.format("HH:mm") }
                            </div>

                            <div className="table-cell">
                                { doctorName }
                            </div>

                            <div
                                className={ classnames(
                                    "table-cell",
                                    { "clickable": patientName !== undefined }
                                ) }
                                onClick={ this.handlePatientClick(patientId) }
                            >
                                { patientName !== undefined ? patientName : "-" }
                            </div>

                            <div className="table-cell">
                                <ActionMenu
                                    isOpen={ id === activePopupId }
                                    onTrigger={ this.handlePopupTrigger(id) }
                                    modalRef={ ref => this.actionMenuRef = ref }
                                >
                                    {/* <button className="secondary">Просмотреть</button>
                                    <button className="secondary">Изменить</button> */}
                                    { patientName &&
                                        <ConfirmationModal
                                            text="Подтвердите отмену записи пациента"
                                            trigger={ (onClick) =>
                                                <button
                                                    className="secondary warning"
                                                    onClick={ onClick }
                                                >Отменить запись</button>
                                            }
                                            onAccept={ this.removePatient(id) }
                                            acceptButtonClassName="warning"
                                        />
                                    }
                                    <ConfirmationModal
                                        text="Подтвердите удаление сеанса"
                                        trigger={ (onClick) =>
                                            <button
                                                className="secondary danger"
                                                onClick={ onClick }
                                            >Удалить</button>
                                        }
                                        onAccept={ this.deleteSeance(id) }
                                        acceptButtonClassName="danger"
                                    />
                                </ActionMenu>
                            </div>
                        </div>
                    )
                }
                </div>
                || <h4>На этот день нет расписания</h4>
            }
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SchedulePage);

function mapResponseDataToSeances(data: any): ISeance[] {
    try {
        return data.map(({ id, date, time, doctor, patient }: any) => ({
            id,
            date: moment(`${date} ${time}`, "YYYY-MM-DD HH:mm"),
            doctorName: doctor ? getFullName(doctor) : "-",
            patientName: patient ? getFullName(patient) : undefined,
            patientId: patient ? patient.id : undefined
        }))
    } catch(error) {
        console.error(error.message);
        return [];
    }
}