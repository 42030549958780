import React, { ReactElement, useState } from "react";

import { RouteComponentProps, withRouter } from "react-router-dom";

import { CancelAppointment } from "./CancelAppointment";
import Modal from "../../../../components/controls/Modal";
import { APP_PAGES } from "../../../../utilities/urls";

export interface ICancelAppointmentButtonProps extends RouteComponentProps {
    token: string;
}

export const CancelAppointmentButton = withRouter(
    ({ token, history }: ICancelAppointmentButtonProps): ReactElement => {
        const [isOpen, setIsOpen] = useState(false);

        const open = () => {
            setIsOpen(true);
        };

        const close = () => {
            setIsOpen(false);
        };

        const handleCancelFinish = () => {
            history.push(APP_PAGES.HOME);
        };

        return (
            <>
                <button className="main danger" onClick={open}>
                    Отменить запись
                </button>

                <Modal open={isOpen} onClose={close}>
                    <CancelAppointment
                        token={token}
                        onAbort={close}
                        onFinish={handleCancelFinish}
                    />
                </Modal>
            </>
        );
    }
);
