import * as React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import Modal from '../Modal';
import Calendar from './Calendar';
import './index.css';

interface IDefaultProps {
    required: boolean,
    valid: boolean
}

interface IProps extends IDefaultProps {
    value?: moment.Moment,
    label?: string,
    onChange: (value: moment.Moment) => void
    availaleDates?: moment.Moment[],
    placeholder?: string,
    width?: number | string
}

interface IState {
    isModalOpen: boolean
}

export default class Datepicker extends React.Component<IProps, IState> {
    static defaultProps: IDefaultProps = {
        required: false,
        valid: true
    }

    public state: IState = {
        isModalOpen: false
    }

    onDayClick = (clickedDay: moment.Moment) => {
        const { value, onChange } = this.props;
        
        if(!clickedDay.isSame(value)) {
            onChange(clickedDay);
        }

        this.setState({
            isModalOpen: false
        })
    }

    switchModalState = (isModalOpen: boolean) =>
        () => this.setState({ isModalOpen })

    render() {
        const { value, label, availaleDates, required, valid, placeholder, width } = this.props,
            { isModalOpen } = this.state;

        return(
            <div className="datepicker-input">
                <div
                    className={ classnames(
                        "clickable trigger-button",
                        {
                            "not-empty": value !== undefined,
                            "invalid": !valid
                        }
                    ) }
                    style={ { width } }
                    onClick={ this.switchModalState(true) }
                >
                    { label &&
                        <label className={ classnames(
                            "datepicker-label",
                            { "required": required }
                        ) }>{ label }</label> 
                    }
                    { placeholder && <div className="datepicker-placeholder">{ placeholder }</div> }
                    { value !== undefined && value.format("DD-MM-YYYY") }  
                </div>

                <Modal
                    open={ isModalOpen }
                    onClose={ this.switchModalState(false) }
                >
                    <div className="datepicker-modal">
                        <div className="clickable close-button" onClick={ this.switchModalState(false) } />
                        { label && <h2>{ label }</h2> }

                        <Calendar
                            value={ value }
                            onDayClick={ this.onDayClick }
                            availableDates={ availaleDates }
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}