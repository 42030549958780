import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { IStore } from '../../Store';
import Input from '../../components/controls/Input';
import { IAuthData, loginAction } from '../../Store/UserInfo';
import './index.css';

interface IStateProps {
    isLoggedIn: boolean
}

const mapStateToProps = ({ user }: IStore): IStateProps => ({
    isLoggedIn: user.token !== undefined
})

interface IDispatchProps {
    authenticate: (payload: IAuthData) => void
}

const mapDispatchToProps: IDispatchProps = {
    authenticate: loginAction
}

type IState = IAuthData;
type IProps = IStateProps & IDispatchProps;

class LoginForm extends React.Component<IProps, IState> {
    public state: IState = {
        login: "",
        password: ""
    }

    render() {
        const { login, password } = this.state,
            { authenticate, isLoggedIn } = this.props;

        return(
            <div
                className="login-form"
                onKeyPress={ ({ key }) => {
                    if(key === "Enter") {
                        authenticate({ login, password })
                    }
                } }
            >
                { isLoggedIn && <Redirect to="/" /> }

                <h1>Вход</h1>
                <br />

                <Input
                    label="Логин"
                    value={ login }
                    onChange={ (login) => this.setState({ login }) }
                />

                <Input
                    label="Пароль"
                    value={ password }
                    onChange={ (password) => this.setState({ password }) }
                    dotted
                />

                <button
                    className="clickable main"
                    onClick={ () => authenticate({ login, password }) }
                >Вход</button>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm)