interface IGetFullNameArgs {
    firstName: string,
    lastName: string,
    patronymic: string
}

function getFullName({ firstName, lastName, patronymic }: IGetFullNameArgs): string {
    return `${lastName} ${firstName} ${patronymic}`;
}

export { getFullName }