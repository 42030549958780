import React, { FC } from "react";

interface IPhoneNumberProps {
    phone: string;
}

function deformatPhone(phone: string): string {
    return phone.replace(/\(\) -/g, "");
}

const PhoneNumber: FC<IPhoneNumberProps> = ({ phone }) => {
    return (
        <a
            style={{ whiteSpace: "nowrap" }}
            href={`tel:${deformatPhone(phone)}`}
        >
            {phone}
        </a>
    );
};

export default PhoneNumber;
