import * as React from 'react';
import { connect } from 'react-redux';

import { IStore } from '../../Store';
import { loadingStartedAction, loadingFinishedAction, setAlertTextAction } from '../../Store/CommonStore';
import { DOCTOR_URLS } from '../../utilities/urls';
import { getFullName, request } from '../../utilities';
import NewDoctorForm from './NewDoctorForm';
import Modal from '../../components/controls/Modal';
import ActionMenu from '../../components/controls/ActionMenu';
import ConfirmationModal from '../../components/controls/ConfirmationModal';
import './index.css';

interface IDoctor {
    id: number,
    name: string,
    info: string
}

interface IStateProps {
    token: string
}

const mapStateToProps = ({ user }: IStore): IStateProps => ({
    token: user.token!
})

interface IDispatchProps {
    loadingStarted: (payload?: any) => void,
    loadingFinished: (payload?: any) => void,
    setAlertText: (payload: string | undefined) => void
}

const mapDispatchToProps: IDispatchProps = {
    loadingStarted: loadingStartedAction,
    loadingFinished: loadingFinishedAction,
    setAlertText: setAlertTextAction
}

interface IState {
    doctors: IDoctor[],
    isCreateModalOpen: boolean,
    activePopupId?: number
}

type IProps = IStateProps & IDispatchProps;

class DoctorsPage extends React.Component<IProps, IState> {
    public state: IState = {
        doctors: [],
        isCreateModalOpen: false
    }

    public actionMenuRef: HTMLDivElement | null = null;
    private _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        this.requestDoctors();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.loadingFinished();
    }

    requestDoctors = () => {
        const { token, loadingStarted, loadingFinished } = this.props;

        loadingStarted();

        request(token).get(DOCTOR_URLS.GET)
            .then(({ data }) => {
                if(this._isMounted) {
                    this.setState({ doctors: mapResponseDataToDoctors(data) });
                }
            }).finally(() => {
                if(this._isMounted) {
                    loadingFinished();
                }
            })
    }

    closePopup = (event: MouseEvent) => {
        if(this.actionMenuRef && !this.actionMenuRef.contains(event.target as Node)) {
            this.setState({ activePopupId: undefined });
            document.removeEventListener("click", this.closePopup);
        }
    }

    handlePopupTrigger = (id: number) => () => {
        if(this.state.activePopupId === undefined) {
            document.addEventListener("click", this.closePopup);
        }
        
        if(this.state.activePopupId === id) {
            document.removeEventListener("click", this.closePopup);
        }

        if(this.state.activePopupId !== id) {
            document.removeEventListener("click", this.closePopup);
            document.addEventListener("click", this.closePopup);            
        }

        this.setState({
            activePopupId: id !== this.state.activePopupId ? id : undefined
        })
    }

    deleteSeance = (id: number) => () => {
        const { loadingStarted, loadingFinished, token, setAlertText } = this.props;

        loadingStarted();

        request(token).delete(DOCTOR_URLS.DELETE(id))
            .then(() => {
                if(this._isMounted) {
                    this.requestDoctors();
                }
            }).catch(({ response: { data } }) => {
                if(this._isMounted) {
                    setAlertText(data);
                }
            }).finally(() => {
                if(this._isMounted) {
                    loadingFinished();
                }
            })
    }

    render() {
        const { doctors, isCreateModalOpen, activePopupId } = this.state;

        return(
            <div className="doctors-page page-content">
                <h1>Врачи</h1>

                <div className="doctors-table">
                    <div className="table-row header">
                        <div className="table-cell">ФИО</div>
                        <div className="table-cell">Информация</div>
                        <div className="table-cell" />
                    </div>

                    { doctors.map(({ id, name, info }) =>
                        <div key={id} className="table-row">
                            <div className="table-cell">{ name }</div>
                            <div className="table-cell">{ info }</div>
                            <div className="table-cell">
                                <ActionMenu
                                    isOpen={ activePopupId === id }
                                    onTrigger={ this.handlePopupTrigger(id) }
                                    modalRef={ ref => this.actionMenuRef = ref }
                                >
                                    <ConfirmationModal
                                        text="Подтвердите удаление врача (его расписание также будет удалено)"
                                        trigger={ (onClick) =>
                                            <button
                                                className="secondary danger"
                                                onClick={ onClick }
                                            >Удалить</button>
                                        }
                                        onAccept={ this.deleteSeance(id) }
                                        acceptButtonClassName="danger"
                                    />
                                </ActionMenu>
                            </div>
                        </div>
                    ) }
                </div>

                <button
                    className="main"
                    onClick={ () => this.setState({ isCreateModalOpen: true }) }
                >Добавить врача</button>

                <Modal
                    className="new-user-modal"
                    open={ isCreateModalOpen }
                    onClose={ () => this.setState({ isCreateModalOpen: false }) }
                >
                    <NewDoctorForm
                        { ...this.props }
                        onCreate={ () => {
                            this.setState({ isCreateModalOpen: false });
                            this.requestDoctors();
                        } }
                    />
                </Modal>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DoctorsPage);

function mapResponseDataToDoctors(data: any): IDoctor[] {
    try {
        return data.map((doctor: any) => ({
            id: doctor.id,
            name: getFullName(doctor),
            info: doctor.info
        }))
    } catch(error) {
        console.error(error.message);

        return []
    }
}