import * as React from 'react';
import classnames from 'classnames';

import './index.css';

export interface IOption<V = any> {
    key: number,
    value: V,
    text: string
}

interface IProps {
    data: IOption[],
    selectedKey?: number,
    onChange: (key: number) => void,
    label?: string,
    placeholder?: string,
    width?: number | string
}

interface IState {
    isActive: boolean
}

export default class Dropdown extends React.Component<IProps, IState> {
    public state: IState = {
        isActive: false
    }

    public dropdownRef: HTMLInputElement | null = null;

    handleDropdownClick = () => {
        if(this.dropdownRef === null) {
            return
        }

        if(this.state.isActive) {
            this.dropdownRef.blur();
        } else {
            this.dropdownRef.focus();
        }
    }

    handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();

        if(this.state.isActive) {
            this.dropdownRef!.blur();
        } else {
            this.dropdownRef!.focus();
        }
    }

    handleSelection = (key: number) => () => {
        const { onChange, selectedKey } = this.props;

        if(selectedKey !== key) {
            onChange(key);
        }
    }

    handleFocusChange = (isActive: boolean) =>
        (e: React.FocusEvent<HTMLInputElement>) => {
            e.preventDefault();
            this.setState({ isActive });
        }

    render() {
        const { isActive } = this.state,
            { data, selectedKey, label, placeholder, width } = this.props,
            displayedValue = selectedKey !== undefined ? data.find(item => item.key === selectedKey)!.text : undefined;

        return(
            <div
                className={ 
                    "clickable dropdown " +
                    classnames({
                        "active": isActive,
                        "empty": selectedKey === undefined
                    })
                }
                style={ { width } }
                title={ displayedValue }
            >
                { label && <label className="dropdown-label">{ label }</label> }
                <input
                    type="text"
                    className="clickable fake-input"
                    ref={ ref => this.dropdownRef = ref }
                    onBlur={ this.handleFocusChange(false) }
                    onFocus={ this.handleFocusChange(true) }
                    onMouseDown={ this.handleInputClick }
                    readOnly
                />
                { displayedValue && <div className="displayed-value">{ displayedValue }</div> }
                { placeholder && <div className="dropdown-placeholder">{ placeholder }</div> }
                <div className="arrow" />

                <div className="list">
                {
                    data.map(option => 
                        <div
                            key={ option.key }
                            className={ `option${ option.key === selectedKey ? " selected": "" }` }
                            onMouseDown={ this.handleSelection(option.key) }
                        >{ option.text }</div>
                    )
                }
                </div>
            </div>
        )
    }
}