import * as React from "react";
import moment from "moment";

import { request, getFullName } from "../../../utilities";
import { AFFILATE_URLS, APPOINTMENT_URLS } from "../../../utilities/urls";
import Dropdown, { IOption } from "../../../components/controls/Dropdown";
import { BaseLoader } from "../../../components/controls/Loader";
import Datepicker from "../../../components/controls/Datepicker";
import "./index.css";
import { PhoneList } from "../PhoneList";
// import PhoneNumber from "../../../components/controls/PhoneNumber";

export interface IAffialateData {
    name: string;
    phone: string;
    address: string;
}

export interface ISeanceData {
    affilate: IAffialateData;
    date: string;
    time: string;
    doctorName: string;
}

interface IAffilateOption extends IOption {
    phone: string;
    address: string;
}

interface IProps {
    setAlertText: (data: string) => void;
    onSeanceChange: (
        seanceId: number | undefined,
        seanceData?: ISeanceData
    ) => void;
    selectedSeanceId: number | undefined;
}

interface IState {
    selectedAffilateId?: number;
    selectedDate?: moment.Moment;
    selectedDoctorId?: number;

    isAffilatesLoading: boolean;
    affilates: IAffilateOption[];

    isDatesLoading: boolean;
    appointableDates?: moment.Moment[];

    isDoctorsLoading: boolean;
    appointableDoctors: IOption[];

    isSeancesLoading: boolean;
    appointableSeances: IOption[];
}

class SeanceSelector extends React.Component<IProps, IState> {
    public state: IState = {
        affilates: [],
        appointableDoctors: [],
        appointableSeances: [],
        isAffilatesLoading: false,
        isDatesLoading: false,
        isDoctorsLoading: false,
        isSeancesLoading: false,
    };

    private _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        this.requestAffilates();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    requestAffilates = () => {
        this.setState({
            isAffilatesLoading: true,
            selectedAffilateId: undefined,
        });

        request()
            .get(AFFILATE_URLS.GET)
            .then(({ data }) => {
                if (this._isMounted) {
                    this.setState({
                        affilates: mapResponseDataToAffilateOptions(data),
                    });
                }
            })
            .catch(({ response: { data } }) => {
                if (this._isMounted) {
                    this.props.setAlertText(data);
                }
            })
            .finally(() => {
                if (this._isMounted) {
                    this.setState({
                        isAffilatesLoading: false,
                    });
                }
            });
    };

    handleAffilateSelect = (affilateId: number) => {
        this.setState({
            selectedAffilateId: affilateId,
            appointableDoctors: [],
            appointableSeances: [],
        });
        this.requestDates(affilateId);
    };

    requestDates = (affilateId: number) => {
        this.setState({
            isDatesLoading: true,
            selectedDate: undefined,
        });

        request()
            .get(
                `${APPOINTMENT_URLS.GET_APPOINTABLE_DATES}?affilateId=${affilateId}`
            )
            .then(({ data }) => {
                if (this._isMounted) {
                    this.setState({
                        appointableDates: mapResponseDataToDates(data),
                    });
                }
            })
            .catch(({ response: { data } }) => {
                if (this._isMounted) {
                    this.props.setAlertText(data);
                }
            })
            .finally(() => {
                if (this._isMounted) {
                    this.setState({ isDatesLoading: false });
                }
            });
    };

    handleDateSelect = (date: moment.Moment) => {
        this.setState({
            selectedDate: date,
            appointableSeances: [],
        });

        this.requestDoctors(
            this.state.selectedAffilateId!,
            date.format("YYYY/MM/DD")
        );
    };

    requestDoctors = (affilateId: number, date: string) => {
        this.setState({
            isDoctorsLoading: true,
            selectedDoctorId: undefined,
        });

        request()
            .get(
                `${APPOINTMENT_URLS.GET_APPOINTABLE_DOCTORS}?affilateId=${affilateId}&date=${date}`
            )
            .then(({ data }) => {
                if (this._isMounted) {
                    this.setState({
                        appointableDoctors:
                            mapResponseDataToDoctorOptions(data),
                    });
                }
            })
            .catch(({ response: { data } }) => {
                if (this._isMounted) {
                    this.props.setAlertText(data);
                }
            })
            .finally(() => {
                if (this._isMounted) {
                    this.setState({ isDoctorsLoading: false });
                }
            });
    };

    handleDoctorSelect = (doctorId: number) => {
        this.setState({ selectedDoctorId: doctorId });

        this.requestSeances(
            this.state.selectedAffilateId!,
            this.state.selectedDate!.format("YYYY/MM/DD"),
            doctorId
        );
    };

    requestSeances = (affilateId: number, date: string, doctorId: number) => {
        const { onSeanceChange, setAlertText } = this.props;

        this.setState({
            isSeancesLoading: true,
        });

        onSeanceChange(undefined);

        request()
            .get(
                APPOINTMENT_URLS.GET_APPOINTABLE_SEANCES(
                    affilateId,
                    date,
                    doctorId
                )
            )
            .then(({ data }) => {
                if (this._isMounted) {
                    this.setState({
                        appointableSeances:
                            mapResponseDataToSeanceOptions(data),
                    });
                }
            })
            .catch(({ response: { data } }) => {
                if (this._isMounted) {
                    setAlertText(data);
                }
            })
            .finally(() => {
                if (this._isMounted) {
                    this.setState({ isSeancesLoading: false });
                }
            });
    };

    handleSeanceSelect = (seanceId: number) => {
        const { onSeanceChange } = this.props,
            {
                affilates,
                selectedAffilateId,
                appointableDates,
                selectedDate,
                appointableSeances,
                appointableDoctors,
                selectedDoctorId,
            } = this.state;

        const selectedAffilate = affilates.find(
            (affilate) => affilate.key === selectedAffilateId
        )!;

        onSeanceChange(seanceId, {
            affilate: {
                name: selectedAffilate.text,
                phone: selectedAffilate.phone,
                address: selectedAffilate.address,
            },
            date: appointableDates!
                .find((date) => date.isSame(selectedDate, "day"))!
                .format("YYYY/MM/DD"),
            time: appointableSeances.find((seance) => seance.key === seanceId)!
                .text,
            doctorName: appointableDoctors.find(
                (doctor) => doctor.key === selectedDoctorId
            )!.text,
        });
    };

    getAffilatePlaceholder = (): React.ReactElement | null => {
        // const selectedAffilate = this.state.affilates.find(
        //     ({ key }) => key === this.state.selectedAffilateId
        // );

        // if (selectedAffilate && selectedAffilate.text === "Пантелеевский") {
        //     return (
        //         <p>
        //             Запись в филиал "Пантелеевский" осуществляется по телефону{" "}
        //             <PhoneNumber phone="+7 (499) 236-59-96" /> с <b>8:00</b> до{" "}
        //             <b>20:00</b> ежедневно
        //         </p>
        //     );
        // }

        return null;
    };

    render() {
        const {
                selectedAffilateId,
                selectedDate,
                selectedDoctorId,
                affilates,
                appointableDates,
                appointableDoctors,
                appointableSeances,
                isAffilatesLoading,
                isDatesLoading,
                isDoctorsLoading,
                isSeancesLoading,
            } = this.state,
            { selectedSeanceId } = this.props;

        const affilatePlaceholder = this.getAffilatePlaceholder();

        return (
            <div className="seance-selector">
                {!isAffilatesLoading ? (
                    <Dropdown
                        label="Филиал"
                        placeholder="Выберите филиал"
                        data={affilates}
                        selectedKey={selectedAffilateId}
                        onChange={this.handleAffilateSelect}
                        width="250px"
                    />
                ) : (
                    <BaseLoader />
                )}

                {affilatePlaceholder || (
                    <>
                        {!isDatesLoading ? (
                            appointableDates !== undefined &&
                            (appointableDates.length !== 0 ? (
                                <Datepicker
                                    label="Дата приёма"
                                    placeholder="Выберите дату приёма"
                                    value={selectedDate}
                                    onChange={this.handleDateSelect}
                                    availaleDates={appointableDates}
                                    width="250px"
                                />
                            ) : (
                                <div className="no-doctors">
                                    <h4 className="no-doctors__header">
                                        В выбранном филиале нет свободных врачей
                                    </h4>

                                    <p className="no-doctors__text">
                                        При невозможности записи пожалуйста
                                        обратитесь в колл-центр:
                                    </p>

                                    <PhoneList isPaid={false} />
                                </div>
                            ))
                        ) : (
                            <BaseLoader />
                        )}

                        {!isDoctorsLoading ? (
                            appointableDoctors.length !== 0 && (
                                <Dropdown
                                    label="Врач"
                                    placeholder="Выберите врача"
                                    data={appointableDoctors}
                                    selectedKey={selectedDoctorId}
                                    onChange={this.handleDoctorSelect}
                                    width="250px"
                                />
                            )
                        ) : (
                            <BaseLoader />
                        )}

                        {!isSeancesLoading ? (
                            appointableSeances.length !== 0 && (
                                <Dropdown
                                    label="Время"
                                    placeholder="Выберите время приёма"
                                    data={appointableSeances}
                                    selectedKey={selectedSeanceId}
                                    onChange={this.handleSeanceSelect}
                                    width="250px"
                                />
                            )
                        ) : (
                            <BaseLoader />
                        )}
                    </>
                )}
            </div>
        );
    }
}

function mapResponseDataToAffilateOptions(data: any): IAffilateOption[] {
    try {
        return data.map((affilate: any) => ({
            key: affilate.id,
            value: affilate.id,
            text: affilate.name,
            phone: affilate.phone,
            address: affilate.address,
        }));
    } catch (error) {
        console.error(error.message);
        return [];
    }
}

function mapResponseDataToDates(data: any): moment.Moment[] {
    try {
        return data.map((date: any) => moment(date, "YYYY/MM/DD"));
    } catch (error) {
        console.error(error.message);
        return [];
    }
}

function mapResponseDataToDoctorOptions(data: any): IOption[] {
    try {
        return data.map((doctor: any) => ({
            key: doctor.id,
            value: doctor.id,
            text: getFullName(doctor),
        }));
    } catch (error) {
        console.error(error);
        return [];
    }
}

function mapResponseDataToSeanceOptions(data: any): IOption[] {
    try {
        return data.map((seance: any) => ({
            key: seance.id,
            value: seance.id,
            text: moment(seance.time, "HH:mm:ss").format("HH:mm"),
        }));
    } catch (error) {
        console.error(error);
        return [];
    }
}

export default SeanceSelector;
