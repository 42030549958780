import * as React from 'react';
import { Redirect } from 'react-router';
import moment from 'moment';

import { APP_PAGES } from '../../utilities/urls';
import Input from '../../components/controls/Input';
import Input2 from '../../components/controls/Input2';
import './index.css';
import Datepicker from '../../components/controls/Datepicker';
import Datepicker2 from '../../components/controls/Datepicker2';
import ActionMenu from '../../components/controls/ActionMenu';
import ConfirmationModal from '../../components/controls/ConfirmationModal';

class DevelopmentPlayground extends React.Component<{}, any> {
    public state: any = {
        inputText: "",
        isPopupOpen: false
    }

    render() {
        return(
            <div className="development-playground">
                <Input
                    label="Некоторый текст"
                    value={ this.state.inputText }
                    onChange={ (inputText) => this.setState({ inputText }) }
                    valid={ this.state.inputText !== "дичь" }
                />

                <Input2
                    label="Некоторый текст"
                    placeholder="Тупо заглушка"
                    value={ this.state.inputText }
                    onChange={ (inputText) => this.setState({ inputText }) }
                    valid={ this.state.inputText !== "дичь" }
                />

                <Datepicker
                    label="Дата"
                    value={ this.state.date }
                    placeholder="ДД-ММ-ГГГГ"
                    onChange={ (date) =>this.setState({ date }) }
                    availaleDates={
                        [
                            moment("10-05-2019", "DD-MM-YYYY"),
                            moment("12-05-2019", "DD-MM-YYYY"),
                            moment("23-05-2019", "DD-MM-YYYY")
                        ]
                    }
                />

                <Datepicker2
                    label="Дата"
                    value={ this.state.date2 }
                    placeholder="ДД-ММ-ГГГГ"
                    required
                    onChange={ (date2) =>this.setState({ date2 }) }
                    availaleDates={
                        [
                            moment("10-05-2019", "DD-MM-YYYY"),
                            moment("12-05-2019", "DD-MM-YYYY"),
                            moment("23-05-2019", "DD-MM-YYYY")
                        ]
                    }
                    valid={ this.state.date2Valid }
                    onValidate={ (date2Valid) => this.setState({ date2Valid }) }
                />
                

                <ActionMenu
                    isOpen={ this.state.isOpen }
                    onTrigger={ () => this.setState(({ isOpen }: { isOpen: boolean }) => ({ isOpen: !isOpen })) }
                >
                    <button className="secondary">Просмотреть</button>
                    <button className="secondary">Изменить</button>
                    <button className="secondary">Удалить</button>
                </ActionMenu>

                <ConfirmationModal
                    trigger={ (onClick) => <button onClick={ onClick }>Действие</button> }
                    text="Вы уверены, что хотите совершить действие?"
                    onAccept={ () => console.log("Action!") }
                />
            </div>
        )
    }
}

export default () => {
    if(process.env.NODE_ENV !== "production") {
        return(<DevelopmentPlayground/>)
    } else {
        return(<Redirect to={ APP_PAGES.HOME } />)
    }
}