import React from "react";
import { Link, Redirect } from "react-router-dom";

import { IAffialateData } from "../SeanceSelector";
import { useLocalAppointmentInfo } from "./utils";
import { getFullName } from "../../../utilities";
import { APP_PAGES } from "../../../utilities/urls";
import OutpatientCardInfo from "../OutpatientCardInfo";
import { CancelAppointmentButton } from "./CancelAppointment";
import "./index.css";

function AppointmentInfo() {
    const appointmentInfo = useLocalAppointmentInfo();

    if (appointmentInfo) {
        const { patientData, seanceData } = appointmentInfo;

        return (
            <div className="appointment-info-page">
                <div className="appointment-info-content">
                    <h2>Талон о записи на приём</h2>
                    <br />
                    <table>
                        <tbody>
                            <tr>
                                <td>Пациент</td>
                                <td>{getFullName(patientData)}</td>
                            </tr>
                            <tr>
                                <td>Филиал</td>
                                <td>{AffialteData(seanceData.affilate)}</td>
                            </tr>
                            <tr>
                                <td>Врач</td>
                                <td>{seanceData.doctorName}</td>
                            </tr>
                            <tr>
                                <td>Дата</td>
                                <td>{seanceData.date}</td>
                            </tr>
                            <tr>
                                <td>Время</td>
                                <td>{seanceData.time}</td>
                            </tr>
                        </tbody>
                    </table>

                    <OutpatientCardInfo />
                </div>

                <div className="button-container">
                    <button className="main" onClick={window.print}>
                        Распечатать
                    </button>

                    {appointmentInfo.cancelToken && (
                        <CancelAppointmentButton
                            token={appointmentInfo.cancelToken}
                        />
                    )}

                    <Link className="button secondary" to={APP_PAGES.HOME}>
                        Назад
                    </Link>
                </div>
            </div>
        );
    } else {
        return <Redirect to={APP_PAGES.HOME} />;
    }
}

function AffialteData({ name, phone, address }: IAffialateData) {
    return (
        <table>
            <tbody>
                <tr>
                    <td>Название</td>
                    <td>{name}</td>
                </tr>
                <tr>
                    <td>Адрес</td>
                    <td>{address}</td>
                </tr>
                <tr>
                    <td>Контактный телефон</td>
                    <td>{phone}</td>
                </tr>
            </tbody>
        </table>
    );
}

export default AppointmentInfo;
