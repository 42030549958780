import 'react-app-polyfill/ie11';
import 'core-js/es/object/assign';
import 'core-js/es/array/find';
import 'core-js/es/promise';
import 'moment/locale/ru';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';
import store from './Store';
import * as serviceWorker from './serviceWorker';
import './index.css';

const renderTree = () => {
    ReactDOM.render(
        <Provider store={ store }>
            <BrowserRouter basename="/">
                <App />
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    )
}

renderTree();

if(module.hot) {
    module.hot.accept('./App', () => {
        renderTree()
    })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
