import React, { ReactElement } from "react";

import { useAppointmentCancellation } from "./useAppointmentCancellation";
import Loader from "../../../../components/controls/Loader";
import "./CancelAppointment.css";

export interface ICancelAppointmentProps {
    token: string;
    onFinish: () => void;
    onAbort: () => void;
}

export const CancelAppointment = ({
    token,
    onAbort,
    onFinish,
}: ICancelAppointmentProps): ReactElement | null => {
    const { state, cancel } = useAppointmentCancellation();

    const confirmCancellation = () => {
        cancel(token);
    };

    switch (state.status) {
        case "idle":
            return (
                <div className="cancel-appointment">
                    <p className="cancel-appointment__text">
                        Вы уверены, что хотите отменить запись на приём?
                    </p>

                    <div className="cancel-appointment__buttons">
                        <button className="main" onClick={confirmCancellation}>
                            Да, отменить
                        </button>

                        <button className="secondary" onClick={onAbort}>
                            Нет, не отменять
                        </button>
                    </div>
                </div>
            );

        case "loading":
            return (
                <div className="cancel-appointment">
                    <Loader />
                </div>
            );

        case "success":
            return (
                <div className="cancel-appointment">
                    <p className="cancel-appointment__text">
                        Запись на приём успешно отменена
                    </p>

                    <button className="main" onClick={onFinish}>
                        Хорошо
                    </button>
                </div>
            );

        case "error":
            return (
                <div className="cancel-appointment">
                    <p className="cancel-appointment__text danger-text">
                        Произошла ошибка при отмене записи на приём
                    </p>

                    <div className="cancel-appointment__buttons">
                        <button className="main" onClick={confirmCancellation}>
                            Попробовать ещё раз
                        </button>

                        <button className="secondary" onClick={onAbort}>
                            Назад
                        </button>
                    </div>
                </div>
            );

        default:
            return null;
    }
};
