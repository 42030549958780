import { Reducer } from 'redux';

import { createAction, IAction } from '../actions';

export enum COMMON_ACTIONS {
    LOADING_STARTED = "COMMON_ACTIONS_LOADING_STARTED",
    LOADING_FINISHED = "COMMON_ACTIONS_LOADING_FINISHED",
    SET_ALERT_TEXT = "COMMON_ACTIONS_SET_ALERT_TEXT"
}

export const loadingStartedAction = createAction(COMMON_ACTIONS.LOADING_STARTED);
export const loadingFinishedAction = createAction(COMMON_ACTIONS.LOADING_FINISHED);
export const setAlertTextAction = createAction<string | undefined>(COMMON_ACTIONS.SET_ALERT_TEXT);

export interface ICommonStore {
    loadingsCount: number,
    alertText?: string
}

const initStore: ICommonStore = {
    loadingsCount: 0
}

export const commonReducer: Reducer<ICommonStore, IAction> = (state = initStore, { type, payload }): ICommonStore => {
    let { loadingsCount } = state;

    switch(type) {
        case COMMON_ACTIONS.LOADING_STARTED:
            loadingsCount++;

            return {
                ...state,
                loadingsCount
            }

        case COMMON_ACTIONS.LOADING_FINISHED:
            if(loadingsCount !== 0) {
                loadingsCount--;
            }
            
            return {
                ...state,
                loadingsCount
            }

        case COMMON_ACTIONS.SET_ALERT_TEXT:
            return {
                ...state,
                alertText: payload
            }

        default:
            return state
    }
}