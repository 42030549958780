import * as React from 'react';

import Input from '../../components/controls/Input';
import Dropdown, { IOption } from '../../components/controls/Dropdown';
import { IAffilate } from '../AffilatesPage';
import { IUserRole } from '../../Store/UserInfo';
import { AFFILATE_URLS, USER_URLS } from '../../utilities/urls';
import { request } from '../../utilities';

const ROLE_OPTIONS = [
    {
        key: 0,
        value: IUserRole.ADMIN,
        text: "Администратор"
    },
    {
        key: 1,
        value: IUserRole.SUPERADMIN,
        text: "Сисадмин"
    }
]

interface IProps {
    token: string,
    loadingStarted: (payload?: any) => void,
    loadingFinished: (payload?: any) => void
}

interface IInputData {
    login: string,
    password: string,
    roleKey?: number,
    affilateKey?: number,
}

interface IState extends IInputData {
    affilates: IOption[],
    canCreate: boolean
}

class NewUserForm extends React.Component<IProps, IState> {
    public state: IState = {
        login: "",
        password: "",

        affilates: [],
        canCreate: false
    }

    private _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        this.props.loadingStarted();

        request().get(AFFILATE_URLS.GET)
            .then(({ data: affilates }) => {
                if(this._isMounted) {
                    this.setState({ affilates: mapAffilatesToOptions(affilates) });
                }
            }).finally(this._isMounted ? this.props.loadingFinished : undefined);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.loadingFinished();
    }

    componentDidUpdate(prevProps: any, prevState: IState) {
        const { login, password, roleKey, affilateKey } = this.state;

        if(
            prevState.login !== login ||
            prevState.password !== password ||
            prevState.roleKey !== roleKey ||
            prevState.affilateKey !== affilateKey
        ) {
            this.setState({
                canCreate:
                    login !== "" &&
                    password !== "" &&
                    roleKey !== undefined &&
                    affilateKey !== undefined
            })
        }
    }

    createUser = () => {
        const { login, password, roleKey, affilateKey, affilates } = this.state;

        this.props.loadingStarted();
        
        request(this.props.token).post(USER_URLS.CREATE,
            {
                login,
                password,
                role: ROLE_OPTIONS[roleKey!].value,
                affilate: affilates.find(affilate => affilate.key === affilateKey)!.value
            }
        )
            .then(({ data }) => {
                console.log(data);
            }).finally(this._isMounted ? this.props.loadingFinished : undefined);
    }

    handleInputChange = <T extends string | number, K extends keyof IInputData>(fieldName: K) =>
        (value: T) => this.setState<K>({ [fieldName]: value });

    render() {
        const { login, password, roleKey, affilateKey, affilates, canCreate } = this.state;

        return(
            <div className="new-user-form">
                <Input
                    label="Логин"
                    value={ login }
                    onChange={ this.handleInputChange("login") } 
                />

                <Input
                    label="Пароль"
                    value={ password }
                    onChange={ this.handleInputChange("password") } 
                    dotted
                />

                <Dropdown
                    label="Роль"
                    data={ ROLE_OPTIONS }
                    selectedKey={ roleKey }
                    onChange={ this.handleInputChange("roleKey") }
                />

                <Dropdown
                    label="Филиал"
                    data={ affilates }
                    selectedKey={ affilateKey }
                    onChange={ this.handleInputChange("affilateKey") }
                />

                <button 
                    className="main"
                    onClick={ this.createUser }
                    disabled={ !canCreate }
                >Добавить</button>
            </div>
        )
    }
}

export default NewUserForm;

function mapAffilatesToOptions(affilates: IAffilate[]): IOption[] {
    return affilates.map(({ id, name }, index) => ({
        key: index,
        value: id,
        text: name
    }));
}