import moment from "moment";
import { useEffect, useState } from "react";

import { LOCAL_STORAGE_APPOINTMENT } from "../../../utilities/constants";
import { ISeanceData } from "../SeanceSelector";

export interface IPatientData {
    firstName: string;
    lastName: string;
    patronymic: string;
    birthdate: moment.Moment | undefined;
    isMoscow: boolean | undefined;
    email: string;
    phone: string;
}

interface IStorablePatientData
    extends Pick<IPatientData, Exclude<keyof IPatientData, "birthdate">> {
    birthdate?: string;
}

export interface IAppointmentInfo {
    patientData: IPatientData;
    seanceData: ISeanceData;
    cancelToken?: string;
}

interface IStorableAppointmentInfo {
    patientData: IStorablePatientData;
    seanceData: ISeanceData;
    cancelToken?: string;
}

function getLocalAppointmentInfo(): IAppointmentInfo | null {
    const appointmentInfoString = localStorage.getItem(
        LOCAL_STORAGE_APPOINTMENT
    );
    let storedAppointmentInfo: IStorableAppointmentInfo | null =
        appointmentInfoString ? JSON.parse(appointmentInfoString) : null;

    if (storedAppointmentInfo) {
        return {
            patientData: {
                ...storedAppointmentInfo.patientData,
                birthdate: moment(
                    storedAppointmentInfo.patientData.birthdate,
                    "YYYY/MM/DD"
                ),
            },
            seanceData: storedAppointmentInfo.seanceData,
            cancelToken: storedAppointmentInfo.cancelToken,
        };
    } else {
        return null;
    }
}

export function useLocalAppointmentInfo(): IAppointmentInfo | null {
    const [appointmentInfo, setAppointmentInfo] =
        useState<IAppointmentInfo | null>(getLocalAppointmentInfo);

    useEffect(() => {
        setAppointmentInfo(getLocalAppointmentInfo());
    });

    return appointmentInfo;
}

function setLocalAppointmentInfo(appointmentInfo: IStorableAppointmentInfo) {
    const {
        patientData: {
            firstName,
            lastName,
            patronymic,
            birthdate,
            isMoscow,
            email,
            phone,
        },
        seanceData,
        cancelToken,
    } = appointmentInfo;

    localStorage.setItem(
        LOCAL_STORAGE_APPOINTMENT,
        JSON.stringify({
            patientData: {
                firstName,
                lastName,
                patronymic,
                birthdate,
                isMoscow,
                email,
                phone,
            },
            seanceData,
            cancelToken,
        })
    );
}

export { getLocalAppointmentInfo, setLocalAppointmentInfo };
