import * as React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import Modal from '../Modal';
import Calendar from '../Datepicker/Calendar';
import './index.css';

interface IDefaultProps {
    required: boolean,
    valid: boolean
}

interface IProps extends IDefaultProps {
    value?: moment.Moment,
    label?: string,
    onChange: (value?: moment.Moment) => void
    availaleDates?: moment.Moment[],
    placeholder?: string,
    width?: number | string,
    onValidate?: (isValid: boolean) => void
}

interface IState {
    isModalOpen: boolean,
    isFocused: boolean,
    inputValue: string
}

export default class Datepicker extends React.Component<IProps, IState> {
    static defaultProps: IDefaultProps = {
        required: false,
        valid: true
    }

    public state: IState = {
        isModalOpen: false,
        isFocused: false,
        inputValue: this.props.value ? this.props.value.format("DD-MM-YYYY") : ""
    }

    public inputRef: HTMLInputElement | null = null;

    componentDidUpdate({ value: prevValue }: IProps) {
        const { value } = this.props,
            { inputValue } = this.state;

        if(value === prevValue) {
            return;
        }

        if(value) {
            if(!value.isSame(moment(inputValue, "DD-MM-YYYY"))) {
                this.setState({
                    inputValue: value.format("DD-MM-YYYY")
                });
            }
        } else {
            if(value !== prevValue) {
                this.setState({
                    inputValue: ""
                });
            }
        }
    }

    onDayClick = (clickedDay: moment.Moment) => {
        const { value, onChange } = this.props;
        
        if(!clickedDay.isSame(value)) {
            onChange(clickedDay);
        }

        this.setState({
            isModalOpen: false
        })
    }

    switchModalState = (isModalOpen: boolean) =>
        (e?: React.MouseEvent<HTMLDivElement>) => {
                e && e.stopPropagation();
                this.setState({ isModalOpen });
            }

    focus = () => {
        this.inputRef && this.inputRef.focus();
    }

    handleInputChange = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        const valueLength = value.length;

        if(valueLength > 10) {
            return;
        }

        if(valueLength == 0) {
            this.props.onChange();
        }

        let significantValue = value.replace(/\D/g, "");

        if(valueLength < this.state.inputValue.length) {
            significantValue = significantValue.substr(0, significantValue.length);
        }

        const formattedValue = insertHyphensIntoDate(significantValue),
            { onValidate } = this.props;

        let localValid = true;

        if(valueLength == 10) {
            const date = moment(formattedValue, "DD-MM-YYYY");

            if(date.isValid()) {
                this.props.onChange(date);
            } else {
                localValid = false;
            }
        }

        this.setState({
            inputValue: formattedValue
        });

        onValidate && onValidate(localValid);
    }

    onBlur = () => {
        const { inputValue } = this.state,
            { valid, onValidate, required } = this.props;

        this.setState({ isFocused: false });

        onValidate && onValidate(
            (inputValue.length == 10 || !required && inputValue.length == 0)
            && moment(inputValue, "DD-MM-YYYY").isValid()
            && valid
        );
    }

    render() {
        const { value, label, availaleDates, required, valid, placeholder, width } = this.props,
            { isModalOpen, isFocused, inputValue } = this.state;

        return(
            <div className="datepicker2-input">
                <div
                    className={ classnames(
                        "datepicker-container",
                        {
                            "not-empty": inputValue !== "",
                            "focused": isFocused,
                            "invalid": !valid
                        }
                    ) }
                    style={ { width } }
                    onClick={ this.focus }
                >
                    { label &&
                        <label className={ classnames(
                            "datepicker-label",
                            { required }
                        ) }>{ label }</label> 
                    }
                    { placeholder && <div className="datepicker-placeholder">{ placeholder }</div> }
                    <input
                        type="tel"
                        ref={ ref => this.inputRef = ref }
                        onFocus={ () => this.setState({ isFocused: true }) }
                        onBlur={ this.onBlur }
                        value={ inputValue }
                        onChange={ this.handleInputChange }
                    />

                    <div
                        className="clickable calendar-button"
                        onClick={ this.switchModalState(true) }
                    />
                </div>

                <Modal
                    open={ isModalOpen }
                    onClose={ this.switchModalState(false) }
                >
                    <div className="datepicker-modal">
                        <div className="clickable close-button" onClick={ this.switchModalState(false) } />
                        { label && <h2>{ label }</h2> }

                        <Calendar
                            value={ value }
                            onDayClick={ this.onDayClick }
                            availableDates={ availaleDates }
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}

function insertHyphensIntoDate(rawDate: string) {
    const rawDateLength = rawDate.length;

    if(rawDateLength > 2) {
        rawDate = rawDate.substr(0, 2) + "-" + rawDate.substr(2);
    }

    if(rawDateLength > 4) {
        rawDate = rawDate.substr(0, 5) + "-" + rawDate.substr(5);
    }

    return rawDate;
}