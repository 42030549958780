import * as React from 'react';
import { connect } from 'react-redux';

import { IStore } from '../../Store';
import { loadingStartedAction, loadingFinishedAction, setAlertTextAction } from '../../Store/CommonStore';
import ActionMenu from '../../components/controls/ActionMenu';
import { request, getFullName } from '../../utilities';
import { PATIENT_URLS } from '../../utilities/urls';
import Input from '../../components/controls/Input';
import './index.css';
import Modal from '../../components/controls/Modal';
import PatientCard from './PatientCard';

interface IStateProps {
    token: string
}

const mapStateToProps = ({ user }: IStore): IStateProps => ({
    token: user.token!
})

interface IDispatchProps {
    loadingStarted: (payload?: any) => void,
    loadingFinished: (payload?: any) => void,
    setAlertText: (payload: string | undefined) => void
}

const mapDispatchToProps: IDispatchProps = {
    loadingStarted: loadingStartedAction,
    loadingFinished: loadingFinishedAction,
    setAlertText: setAlertTextAction
}

interface IPatient {
    id: number,
    name: string,
    phone: string
}

interface IState {
    pageNum: number,
    searchString: string,
    patients: IPatient[],
    activePopupId?: number,
    searchTimer: NodeJS.Timeout | null
    activePatientId?: number
}

type IProps = IStateProps & IDispatchProps;

class PatientsPage extends React.Component<IProps, IState> {
    public state: IState = {
        pageNum: 0,
        searchString: "",
        patients: [],
        searchTimer: null
    }

    private _isMounted = false;

    componentDidMount() {
        this._isMounted = true;

        const { pageNum, searchString } = this.state;
        this.requestPatients(pageNum, searchString);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.loadingFinished();
    }

    requestPatients = (pageNum: number, searchString: string) => {
        const { loadingStarted, loadingFinished, token, setAlertText } = this.props;
        
        loadingStarted();

        request(token).get(PATIENT_URLS.GET(pageNum, searchString))
            .then(({ data }) => {
                if(this._isMounted) {
                    this.setState({ patients: mapResponseDataToPatients(data) });
                }
            }).catch(({ response: { data } }) => {
                if(this._isMounted) {
                    setAlertText(data);
                }
            }).finally(() => {
                if(this._isMounted) {
                    loadingFinished();
                }
            })
    }

    handlePopupTrigger = (id: number) => () =>
        this.setState({
            activePopupId: id !== this.state.activePopupId ? id : undefined
        });

    handlePageChange = (direction: "inc" | "dec") => () =>
        this.setState(({ pageNum, searchString }) => {
            let nextPageNum = pageNum;

            switch(direction) {
                case "inc":
                    nextPageNum = pageNum + 1;
                    break;
                
                case "dec":
                    nextPageNum = pageNum - 1;
                    break;
            }

            this.requestPatients(nextPageNum, searchString);
            return {
                pageNum: nextPageNum
            }
        });

    handleSearchChange = (searchString: string) => {
        const { searchTimer, pageNum } = this.state;

        if(searchTimer) {
            clearTimeout(searchTimer);
        }

        this.setState({
            searchString,
            searchTimer: setTimeout(() => {
                this.requestPatients(pageNum, searchString)
            }, 800)
        });

        
    }

    handlePatientClick = (patientId?: number) => () =>
        this.setState({ activePatientId: patientId })

    render() {
        const {
                patients,
                activePopupId,
                pageNum,
                searchString,
                activePatientId
            } = this.state;

        return(
            <div className="patients-page page-content">
                <h1>Пациенты</h1>
                
                <div className="patients-filter">
                    <Input
                        label="Поиск"
                        value={searchString}
                        onChange={this.handleSearchChange}
                    />

                    <div className="page-selector">
                        <span className="page-label">Страница:</span>
                        { pageNum > 0 &&
                            <div
                                className="clickable page-changer dec"
                                onClick={ this.handlePageChange("dec") }
                            />
                        }
                        <div className="page-number">{ pageNum + 1 }</div>
                        <div
                            className="clickable page-changer inc"
                            onClick={ this.handlePageChange("inc") }
                        />
                    </div>
                </div>
            
                { patients.length !== 0 ?
                    <div className="patients-table">
                        <div className="table-row header">
                            <div className="table-cell">Пациент</div>
                            <div className="table-cell">Номер телефона</div>
                            <div className="table-cell" />
                        </div>
                    {
                        patients.map(({ id, name, phone }) =>
                            <div key={ id } className="table-row">
                                <div className="table-cell">
                                    { name }
                                </div>

                                <div className="table-cell">
                                    { phone }
                                </div>

                                <div className="table-cell">
                                    <ActionMenu
                                        isOpen={ id === activePopupId }
                                        onTrigger={ this.handlePopupTrigger(id) }
                                    >
                                        <button
                                            className="secondary"
                                            onClick={ this.handlePatientClick(id) }
                                        >Просмотреть</button>
                                    </ActionMenu>
                                </div>
                            </div>
                        )
                    }
                    </div>
                    : <h4>Список пациентов пуст</h4>
                }

                { activePatientId &&
                    <Modal
                        onClose={ this.handlePatientClick() }
                        open
                    >
                        <PatientCard patientId={ activePatientId } />
                    </Modal>
                }
            </div>
        )
    }
}

function mapResponseDataToPatients(data: any): IPatient[] {
    try {
        return data.map((patient: any) => ({
            id: patient.id,
            name: getFullName(patient),
            phone: patient.phone
        }))
    } catch(error) {
        console.error(error.message);
        return []
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientsPage);