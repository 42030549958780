import * as React from "react";

import Input from "../../components/controls/Input";
import { request } from "../../utilities";
import { AFFILATE_URLS } from "../../utilities/urls";
import { IAffilate } from ".";

export interface IEditProps {
	type: "edit";
  	affilate: IAffilate;
}

export interface ICreateProps {
	type: "create";
}

interface IBasicProps {
	onSubmit: () => void;
	token: string;
}

type IProps = IBasicProps & (IEditProps | ICreateProps);

interface IState {
	name: string;
	address: string;
	phone: string;
}

class AffilateForm extends React.Component<IProps, IState> {
	public state: IState = {
		name: "",
		address: "",
		phone: ""
	};

	componentDidMount() {
		if(this.props.type === "edit") {
			const { name, address, phone } = this.props.affilate;

			this.setState({
				name, address, phone
			})
		}
	}

	createAffilate = () => {
		if (this.props.type !== "create") return;

		const { token, onSubmit } = this.props,
			{ name, address, phone } = this.state;

		request(token)
			.post(AFFILATE_URLS.CREATE, { name, address, phone })
			.then(({ data }) => {
				console.log(data);
				onSubmit();
			});
	};

	editAffilate = () => {
		if (this.props.type !== "edit") return;

		const { token, onSubmit, affilate: { id } } = this.props,
			{ name, address, phone } = this.state;

		request(token)
			.post(AFFILATE_URLS.EDIT, { id, name, address, phone })
			.then(({ data }) => {
				console.log(data);
				onSubmit();
			});
	};

	renderButton= () => {
		switch(this.props.type) {
			case "create":
				return (
					<button className="main" onClick={this.createAffilate}>
						Добавить
					</button>
				);

			case "edit":
				return (
					<button className="main" onClick={this.editAffilate}>
						Изменить
					</button>
				);
		}
	}

	render() {
		const { name, address, phone } = this.state;

		return (
			<div className="affilate-form">
				<Input
					label="Название"
					value={name}
					onChange={name => this.setState({ name })}
				/>

				<Input
					label="Адрес"
					value={address}
					onChange={address => this.setState({ address })}
				/>

				<Input
					label="Номер телефона"
					value={phone}
					onChange={phone => this.setState({ phone })}
				/>

				{ this.renderButton() }
			</div>
		);
	}
}

export default AffilateForm;
