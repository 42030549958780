import { useEffect, useState } from "react";

import { APPOINTMENT_URLS } from "../../../../utilities/urls";
import { LOCAL_STORAGE_APPOINTMENT } from "../../../../utilities/constants";
import { request } from "../../../../utilities";

export type ICancellationState =
    | { status: "idle" }
    | { status: "loading"; cancelToken: string }
    | { status: "success" }
    | { status: "error" };

export interface IAppointmentCancellation {
    state: ICancellationState;
    cancel: (cancelToken: string) => void;
}

export function useAppointmentCancellation(): IAppointmentCancellation {
    const [state, setState] = useState<ICancellationState>(() => ({
        status: "idle",
    }));

    useEffect(() => {
        if (state.status === "loading") {
            request()
                .get(APPOINTMENT_URLS.CANCEL_APPOINTMENT(state.cancelToken))
                .then(() => {
                    localStorage.removeItem(LOCAL_STORAGE_APPOINTMENT);
                    setState({ status: "success" });
                })
                .catch(() => {
                    setState({ status: "error" });
                });
        }
    }, [state]);

    const cancel = (cancelToken: string) => {
        setState({ status: "loading", cancelToken });
    };

    return {
        state,
        cancel,
    };
}
