import { LOCAL_STORAGE_USER_INFO } from './constants';
import { IUserStore } from '../Store/UserInfo';

const userDataString = localStorage.getItem(LOCAL_STORAGE_USER_INFO);
let userData: IUserStore=
        userDataString ?
            JSON.parse(userDataString) :
            {};

const getUserToken = () => userData.token;

const getUserRole = () => userData.role;

const getUserAffilate = () => userData.affilate;

export { getUserToken, getUserRole, getUserAffilate };
