import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";

import Input from "../../components/controls/Input2";
import Datepicker2 from "../../components/controls/Datepicker2";
import SegmentedSwitch from "../../components/controls/SegmentedSwitch";
import {
    loadingStartedAction,
    loadingFinishedAction,
    setAlertTextAction,
} from "../../Store/CommonStore";
import SeanceSelector, { ISeanceData } from "./SeanceSelector";
import { request } from "../../utilities";
import { APPOINTMENT_URLS, APP_PAGES } from "../../utilities/urls";
import Modal from "../../components/controls/Modal";
import RegInfo from "./RegInfo";
import {
    getLocalAppointmentInfo,
    IAppointmentInfo,
    IPatientData,
    setLocalAppointmentInfo,
} from "./AppointmentInfo/utils";
import WarningWindow from "./WarningWindow";
import "./index.css";

const MIN_AGE = 18;
const UNDERAGE_WARNING =
    "Запись доступна только для лиц, достигших 18-ти летнего возраста";

interface IDispatchProps {
    loadingStarted: (payload?: any) => void;
    loadingFinished: (payload?: any) => void;
    setAlertText: (payload: string | undefined) => void;
}

const mapDispatchToProps: IDispatchProps = {
    loadingStarted: loadingStartedAction,
    loadingFinished: loadingFinishedAction,
    setAlertText: setAlertTextAction,
};

type IProps = IDispatchProps;

interface IValidFields {
    firstName: boolean | undefined;
    lastName: boolean | undefined;
    patronymic: boolean | undefined;
    birthdate: boolean | undefined;
    isMoscow: boolean | undefined;
    email: boolean | undefined;
    phone: boolean | undefined;
    seanceId: boolean | undefined;
}

interface IState extends IPatientData {
    seanceId?: number;
    seanceData?: ISeanceData;
    validFields: IValidFields;
    isUnderage: boolean;
    appointmentSucceeded: boolean;
    isInfoOpen: boolean;
    lastAppointment: IAppointmentInfo | null;
}

class RegForm extends React.Component<IProps, IState> {
    public state: IState = {
        firstName: "",
        lastName: "",
        patronymic: "",
        birthdate: undefined,
        isMoscow: undefined,
        email: "",
        phone: "",
        validFields: {
            firstName: undefined,
            lastName: undefined,
            patronymic: undefined,
            birthdate: undefined,
            isMoscow: undefined,
            email: true,
            phone: undefined,
            seanceId: undefined,
        },
        isUnderage: false,
        appointmentSucceeded: false,
        isInfoOpen: false,
        lastAppointment: null,
    };

    private _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            lastAppointment: getLocalAppointmentInfo(),
        });
    }

    componentDidUpdate(prevProps: any, prevState: Readonly<IState>) {
        const { birthdate } = this.state;

        if (birthdate && !birthdate.isSame(prevState.birthdate)) {
            const minBirthdate = moment().subtract(MIN_AGE, "years");

            if (birthdate.isAfter(minBirthdate)) {
                this.setState({ isUnderage: true });
            } else {
                this.setState({ isUnderage: false });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.loadingFinished();
    }

    handleSeanceSelect = (
        seanceId: number | undefined,
        seanceData?: ISeanceData
    ) => {
        this.setState({
            seanceId,
            seanceData,
            validFields: {
                ...this.state.validFields,
                seanceId: seanceId !== undefined,
            },
        });
    };

    handleSelectorInputChange =
        <F extends keyof Pick<IState, "birthdate" | "isMoscow">>(
            fieldName: F
        ) =>
        (value: IState[F]) => {
            const state = { ...this.state },
                validFields = { ...state.validFields };

            validFields[fieldName] = true;
            state.validFields = validFields;
            if (fieldName == "isMoscow") {
                state[fieldName] = !!value;
            } else {
                state[fieldName] = value;
            }
            this.setState(state);
        };

    handleTextInputChange =
        <
            F extends keyof Pick<
                IState,
                "firstName" | "lastName" | "patronymic" | "phone" | "email"
            >
        >(
            fieldName: F
        ) =>
        (value: IState[F]) => {
            const state = { ...this.state },
                validFields = { ...state.validFields };

            switch (fieldName) {
                case "firstName":
                case "lastName":
                case "patronymic":
                    validFields[fieldName] = /^[а-яА-ЯЁё \-]+$/.test(value);
                    break;

                case "phone":
                    validFields[fieldName] = /^\+?[0-9\-\(\) ]+$/.test(value);
                    break;

                case "email":
                    validFields[fieldName] =
                        /^([a-zA-Z.\-0-9]+@[a-zA-Z0-9]+[\.][a-zA-Z]+)?$/.test(
                            value
                        );
                    break;
            }
            state.validFields = validFields;
            state[fieldName] = value;
            this.setState(state);
        };

    validateFields = () => {
        const validFields = { ...this.state.validFields };
        let isValid = true;

        Object.entries(validFields).forEach(([key, value]) => {
            if (!value) {
                isValid = false;
                validFields[key] = false;
            }
        });

        this.setState({ validFields });

        return isValid;
    };

    makeAppointment = () => {
        const { loadingStarted, loadingFinished, setAlertText } = this.props;

        if (!this.validateFields()) return;

        if (this.state.isUnderage) {
            setAlertText(UNDERAGE_WARNING);
            return;
        }

        loadingStarted();

        const {
            isMoscow,
            lastName,
            firstName,
            patronymic,
            birthdate,
            email,
            phone,
            seanceId,
            seanceData,
        } = this.state;

        const appointmentData: IMakeAppointmentRequestBody = {
            isMoscow: isMoscow!,
            lastName,
            firstName,
            patronymic,
            birthdate: birthdate!.format("YYYY/MM/DD"),
            email,
            phone,
            seanceId: seanceId!,
        };

        request()
            .put(APPOINTMENT_URLS.MAKE_APPOINTMENT, appointmentData)
            .then(({ data: cancelToken }) => {
                setLocalAppointmentInfo({
                    patientData: appointmentData,
                    seanceData: seanceData!,
                    cancelToken,
                });

                if (this._isMounted) {
                    this.setState({ appointmentSucceeded: true });
                }
            })
            .catch(({ response: { data } }) => {
                if (this._isMounted) {
                    setAlertText(data);
                }
            })
            .finally(() => {
                if (this._isMounted) {
                    loadingFinished();
                }
            });
    };

    autofill = () => {
        const { patientData } = this.state.lastAppointment!,
            { validFields } = this.state;

        this.setState({
            ...patientData,
            validFields: {
                ...validFields,
                firstName: !!patientData.firstName,
                lastName: !!patientData.lastName,
                patronymic: !!patientData.patronymic,
                birthdate: !!patientData.birthdate,
                isMoscow: !!patientData.isMoscow,
                email: true,
                phone: !!patientData.phone,
            },
        });
    };

    setModalState = (state: "open" | "close") => () => {
        switch (state) {
            case "open":
                this.setState({ isInfoOpen: true });
                break;

            case "close":
                this.setState({ isInfoOpen: false });
                break;
        }
    };

    render() {
        const {
                isMoscow,
                firstName,
                lastName,
                patronymic,
                birthdate,
                email,
                phone,
                seanceId,
                validFields,
                isUnderage,
                appointmentSucceeded,
                isInfoOpen,
                lastAppointment,
            } = this.state,
            { setAlertText } = this.props;

        return (
            <div className="reg-form page-content">
                {appointmentSucceeded && (
                    <Redirect to={APP_PAGES.APPOINTMENT_INFO} />
                )}

                <Modal open={isInfoOpen} onClose={this.setModalState("close")}>
                    <RegInfo onClose={this.setModalState("close")} />
                </Modal>

                <div className="block" style={{ marginBottom: 0 }}>
                    <button
                        className="secondary"
                        onClick={this.setModalState("open")}
                    >
                        Информация
                    </button>

                    {/* <button className="secondary">Обратная связь</button> */}

                    {lastAppointment && (
                        <Link
                            className="secondary button"
                            to={APP_PAGES.APPOINTMENT_INFO}
                        >
                            Предыдущая запись
                        </Link>
                    )}
                </div>

                <h1 style={{ marginTop: 0 }}>Запись на приём</h1>

                <h2>Выбор даты приёма</h2>

                <div className="block">
                    <SeanceSelector
                        setAlertText={setAlertText}
                        selectedSeanceId={seanceId}
                        onSeanceChange={this.handleSeanceSelect}
                    />
                </div>

                <h2>Персональная информация</h2>
                {lastAppointment && (
                    <div className="block">
                        <button className="secondary" onClick={this.autofill}>
                            Заполнить автоматически
                        </button>
                    </div>
                )}

                <div className="block-label required">
                    Наличие постоянной регистрации (прописки) в г. Москва
                </div>
                <div className="block">
                    <SegmentedSwitch
                        data={[
                            { key: 1, value: true, text: "Есть" },
                            { key: 0, value: false, text: "Отсутствует" },
                        ]}
                        selectedKey={
                            isMoscow !== undefined ? +isMoscow : undefined
                        }
                        onChange={this.handleSelectorInputChange("isMoscow")}
                        width={300}
                        valid={validFields.isMoscow}
                    />
                </div>

                {isMoscow === false ? (
                    <div className="block">
                        <WarningWindow />
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="block">
                            <Input
                                label="Фамилия"
                                placeholder="Иванов"
                                value={lastName}
                                onChange={this.handleTextInputChange(
                                    "lastName"
                                )}
                                valid={validFields.lastName}
                                required
                            />

                            <Input
                                label="Имя"
                                placeholder="Иван"
                                value={firstName}
                                onChange={this.handleTextInputChange(
                                    "firstName"
                                )}
                                valid={validFields.firstName}
                                required
                            />

                            <Input
                                label="Отчество"
                                placeholder="Иванович"
                                value={patronymic}
                                onChange={this.handleTextInputChange(
                                    "patronymic"
                                )}
                                valid={validFields.patronymic}
                                required
                            />
                        </div>

                        <div className="block">
                            <Datepicker2
                                label="Дата рождения"
                                value={birthdate}
                                onChange={this.handleSelectorInputChange(
                                    "birthdate"
                                )}
                                placeholder="Выберите дату"
                                valid={validFields.birthdate}
                                onValidate={(birthdate) =>
                                    this.setState({
                                        validFields: {
                                            ...validFields,
                                            birthdate,
                                        },
                                    })
                                }
                                required
                            />
                        </div>

                        {isUnderage && (
                            <div className="block danger-text">
                                {UNDERAGE_WARNING}
                            </div>
                        )}

                        <br />
                        <h2>Контактные данные</h2>

                        <div className="block">
                            <Input
                                label="E-mail"
                                placeholder="info@npcpn.ru"
                                value={email}
                                onChange={this.handleTextInputChange("email")}
                                valid={validFields.email}
                            />

                            <Input
                                label="Номер телефона"
                                placeholder="+7 (499) 237-41-53"
                                value={phone}
                                onChange={this.handleTextInputChange("phone")}
                                valid={validFields.phone}
                                required
                            />
                        </div>

                        <div className="block centered">
                            <button
                                className="main send"
                                onClick={this.makeAppointment}
                                disabled={seanceId === undefined}
                            >
                                Записаться
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default connect(() => ({}), mapDispatchToProps)(RegForm);

interface IMakeAppointmentRequestBody {
    lastName: string;
    firstName: string;
    patronymic: string;
    birthdate: string;
    email: string;
    phone: string;
    isMoscow: boolean;
    seanceId: number;
}
