import * as React from 'react';
import moment  from 'moment';
import { connect } from 'react-redux';

import { ISeance } from '../../SchedulePage';
import { loadingFinishedAction, loadingStartedAction } from '../../../Store/CommonStore';
import { IStore } from '../../../Store';
import { request } from '../../../utilities';
import { getFullName } from '../../../utilities';
import { PATIENT_URLS } from '../../../utilities/urls';
import './index.css';

interface IStateProps {
    token?: string
}

interface IDispatchProps {
    loadingStarted: (payload?: any) => void,
    loadingFinished: (payload?: any) => void
}

interface IState {
    patient?: {
        name: string,
        birthdate: moment.Moment,
        isMoscow: boolean,
        phone: string,
        email: string,
        seances: ISeance[]
    }
}

interface IOwnProps {
    patientId: number
}

const mapStateToProps = ({ user }: IStore, ownProps: IOwnProps): IStateProps => ({
    token: user.token
});

const mapDispatchToProps: IDispatchProps = {
    loadingStarted: loadingStartedAction,
    loadingFinished: loadingFinishedAction
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

class PatientCard extends React.Component<IProps, IState> {
    public state: IState = {}

    public _isMounted = false;

    componentDidMount() {
        this._isMounted = true;

        const { token, loadingStarted, loadingFinished, patientId } = this.props;

        loadingStarted();
        request(token).get(PATIENT_URLS.GET_PATIENT(patientId))
            .then(({ data }) => {
                if(this._isMounted) {
                    this.setState(mapResponseToState(data));
                }
            })
            .catch(error => {
                console.error(error.message);
            })
            .finally(() => {
                loadingFinished();
            })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const { patient } = this.state;

        return(
            <div className="patient-card">
                <h2>Пациент</h2>
                { patient &&
                    <React.Fragment>
                        <table className="patient-info">
                            <tbody>
                                <tr>
                                    <td>ФИО</td>
                                    <td>{ patient.name }</td>
                                </tr>
                                <tr>
                                    <td>Дата рождения</td>
                                    <td>{ patient.birthdate.format("DD-MM-YYYY") }</td>
                                </tr>
                                <tr>
                                    <td>Московская прописка</td>
                                    <td>{ patient.isMoscow ? "Есть" : "Отсутствует" }</td>
                                </tr>
                                <tr>
                                    <td>Номер телефона</td>
                                    <td>{ patient.phone }</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{ patient.email }</td>
                                </tr>
                            </tbody>
                        </table>

                        <br />
                        <h3>Записи на приём</h3>
                        <table className="patient-seances">
                            <thead>
                                <tr className="header">
                                    <td>Дата и время</td>
                                    <td>Врач</td>
                                </tr>
                            </thead>
                            <tbody>
                                { patient.seances.map(({ date, doctorName }) =>
                                    <tr key={ date.valueOf() }>
                                        <td>{ date.format("DD-MM-YYYY HH:mm") }</td>
                                        <td>{ doctorName }</td>
                                    </tr>
                                ) }
                            </tbody>
                        </table>
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientCard);

function mapResponseToState(data: any): IState {
    try {
        return {
            patient: {
                name: getFullName(data),
                birthdate: moment(data.birthdate, "YYYY/MM/DD"),
                isMoscow: data.isMoscow,
                phone: data.phone,
                email: data.email,
                seances: data.seances.map((seance: any) => ({
                    id: seance.id,
                    date: moment(`${seance.date} ${seance.time}`, "YYYY/MM/DD HH:mm:ss"),
                    doctorName: getFullName(seance.doctor)
                }))
            }
        }
    } catch(error) {
        console.error(error.message);
        return {};
    }
}