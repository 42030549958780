import React, { ReactElement } from "react";
import PhoneNumber from "../../components/controls/PhoneNumber";

interface IPhoneEntry {
    name: string;
    address: string;
    phone: string;
    phoneMeta?: string;
}

function getPhoneList(isPaid: boolean): IPhoneEntry[] {
    return [
        {
            name: "центр на Шаболовской",
            address: "Донская, 43",
            phone: "+7 (499) 237-41-53",
            phoneMeta: "многоканальный",
        },
        {
            name: "филиал № 2 на Проспекте Мира",
            address: "Пантелеевская, 10",
            phone: "+7 (499) 237-41-53",
            phoneMeta: "многоканальный",
        },
        {
            name: "филиал № 3",
            address: "Пантелеевская, 10",
            phone: "+7 (499) 237-41-53",
            phoneMeta: "многоканальный",
        },
    ];
}

export interface IPhoneListProps {
    isPaid: boolean;
}

export const PhoneList = ({ isPaid }: IPhoneListProps): ReactElement => {
    const phones = getPhoneList(isPaid);

    return (
        <ul className="list" style={{ margin: 0 }}>
            {phones.map(({ name, address, phone, phoneMeta }) => (
                <li>
                    {name} <b>({address})</b>: <PhoneNumber phone={phone} />
                    {phoneMeta && ` (${phoneMeta})`};
                </li>
            ))}
        </ul>
    );
};
