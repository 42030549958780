import { useEffect, useState } from "react";

import { getUserToken, request } from "../../utilities";
import { AUDIT_URLS } from "../../utilities/urls";

interface PatientAction {
    id: string;
    createdAt: string;
    seanceDate: string;
    seanceTime: string;
    doctorName: string;
    type: "0" | "1";
    patient: {
        id: string;
        lastName: string;
        firstName: string;
        patronymic: string;
        email: string;
        phone: string;
        birthdate: string;
        isMoscow: true;
    };
}

interface PatientActionsAPI {
    patientSearch: string;
    setPatientSearch: (searchString: string) => void;
    patientActions: PatientAction[];
    doctorSearch: string;
    setDoctorSearch: (searchString: string) => void;
    isLoading: boolean;
    isNotFound: boolean;
}

const INITIAL_USERS: PatientAction[] = [];
// Array(10)
//     .fill(true)
//     .map(
//         (_, index): PatientAction => ({
//             id: `6096e6ec-7d5c-4f99-b658-59fc4a1401c${index}`,
//             createdAt: "2022-03-10T22:09:24.263Z",
//             seanceDate: "2022-03-12",
//             seanceTime: "10:20:00",
//             doctorName: "Каледин Захар Александрович",
//             patient: {
//                 id: "12345678901646948196314",
//                 lastName: "Константинопольский",
//                 firstName: "Константин",
//                 patronymic: "Константинович",
//                 email: "",
//                 phone: "1234567890",
//                 birthdate: "1997-09-28",
//                 isMoscow: true,
//             },
//         })
//     );

export function usePatientActions(): PatientActionsAPI {
    const [patientActions, setPatientActions] =
        useState<PatientAction[]>(INITIAL_USERS);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataRequested, setIsDataRequested] = useState(false);
    const [patientSearch, setPatientSearch] = useState("");
    const [doctorSearch, setDoctorSearch] = useState("");

    const isNotFound =
        !isLoading &&
        isDataRequested &&
        (doctorSearch.length !== 0 || patientSearch.length !== 0) &&
        patientActions.length === 0;

    useEffect(() => {
        if (patientSearch.length === 0 && doctorSearch.length === 0) {
            setIsDataRequested(false);
            setPatientActions(INITIAL_USERS);
            return;
        }

        const timer = setTimeout(() => {
            setIsLoading(true);

            request(getUserToken())
                .get(AUDIT_URLS.PATIENT_ACTIONS, {
                    params: { patientSearch, doctorSearch },
                })
                .then(({ data }) => {
                    setIsLoading(false);
                    setIsDataRequested(true);
                    setPatientActions(data);
                })
                .catch(() => {
                    setIsDataRequested(false);
                    setIsLoading(false);
                });
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [patientSearch, doctorSearch]);

    return {
        patientActions,
        isLoading,
        isNotFound,
        patientSearch,
        setPatientSearch,
        doctorSearch,
        setDoctorSearch,
    };
}
