export const APP_PAGES = {
    HOME: "/",
    APPOINTMENT_INFO: "/appointmentInfo",
    LOGIN: "/login",
    AFFILATES: "/affilates",
    USERS: "/users",
    SCHEDULE: "/schedule",
    DOCTORS: "/doctors",
    PATIENTS: "/patient",
    PATIENT_ACTIONS: "/audit/patientActions",
};

export const AUTH_URLS = {
    LOGIN: "/auth/login",
    VERIFY_TOKEN: "/auth/verifyToken",
};

export const USER_URLS = {
    GET: "/users",
    CREATE: "/users/create",
};

export const AFFILATE_URLS = {
    GET: "/affilates",
    CREATE: "/affilates/create",
    EDIT: "/affilates/edit",
};

export const SEANCE_URLS = {
    GET: "/seances",
    GENERATE_SCHEDULE: "/seances/generate",
    DELETE_SEANCE: (id: number) => `/seances/delete/${id}`,
    REMOVE_PATIENT: (seanceId: number) => `/seances/removePatient/${seanceId}`,
};

export const PATIENT_URLS = {
    GET: (pageNum: number = 0, searchString: string = "") =>
        `/patients?pageNum=${pageNum}&searchString=${searchString}`,
    GET_PATIENT: (id: number) => `/patients/${id}`,
};

export const APPOINTMENT_URLS = {
    GET_APPOINTABLE_DATES: "/appointment/appointableDates",
    GET_APPOINTABLE_DOCTORS: "/appointment/appointableDoctors",
    GET_APPOINTABLE_SEANCES: (
        affilateId: number,
        date: string,
        doctorId: number
    ) =>
        `/appointment/appointableSeances?affilateId=${affilateId}&date=${date}&doctorId=${doctorId}`,
    MAKE_APPOINTMENT: "/appointment/makeAppointment",
    CANCEL_APPOINTMENT: (appointmentToken: string) =>
        `/appointment/removeAppointment/${appointmentToken}`,
};

export const DOCTOR_URLS = {
    GET: "/doctors",
    CREATE: "/doctors/create",
    DELETE: (id: number) => `/doctors/delete/${id}`,
};

export const AUDIT_URLS = {
    PATIENT_ACTIONS: "/actions",
};
