import * as React from 'react';

import './index.css';

const BaseLoader = () =>
    <div className="loader">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
    </div>

const Loader = () =>
    <div className="loader-backdrop">
        <BaseLoader />
    </div>

export default Loader;
export { BaseLoader };
