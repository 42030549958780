import * as React from 'react';
import { connect } from 'react-redux';
import { isNull } from 'lodash'

import { AFFILATE_URLS } from '../../utilities/urls';
import { IStore } from '../../Store';
import { loadingStartedAction, loadingFinishedAction } from '../../Store/CommonStore';
import Modal from '../../components/controls/Modal';
import AffilateForm, { IEditProps, ICreateProps } from './AffilateForm';
import { request } from '../../utilities';
import './index.css';

interface IStateProps {
    token: string
}

const mapStateToProps = ({ user }: IStore): IStateProps => ({
    token: user.token!
})

interface IDispatchProps {
    loadingStarted: (payload?: any) => void,
    loadingFinished: (payload?: any) => void
}

const mapDispatchToProps: IDispatchProps = {
    loadingStarted: loadingStartedAction,
    loadingFinished: loadingFinishedAction
}

export interface IAffilate {
    id: number,
    name: string,
    address: string,
    phone: string
}

interface IState {
    affilates: IAffilate[];
    isCreateModalOpen: boolean;
    editingAffilateId: number | null;
}

type IProps = IStateProps & IDispatchProps;

class UsersPage extends React.Component<IProps, IState> {
    public state: IState = {
        affilates: [],
        isCreateModalOpen: false,
        editingAffilateId: null
    }

    private _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        this.props.loadingStarted();

        request().get(AFFILATE_URLS.GET)
            .then(({ data }) => {
                if(this._isMounted) {
                    this.setState({ affilates: data });
                }
            }).finally(() => {
                if(this._isMounted) {
                    this.props.loadingFinished();
                }
            })
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.loadingFinished();
    }

    openEditor = (editingAffilateId: number) => () => {
        this.setState({ editingAffilateId })
    }

    closeModal = () => {
        this.setState({
            isCreateModalOpen: false,
            editingAffilateId: null
        })
    }

    render() {
        const { affilates, isCreateModalOpen, editingAffilateId } = this.state,
            { token } = this.props,
            modalProps: IEditProps | ICreateProps = !isNull(editingAffilateId)
                ? { type: "edit", affilate: affilates.find(({ id }) => id === editingAffilateId)! }
                : { type: "create" };

        return(
            <div className="users-page page-content">
                <ul>
                { affilates.map(({ id, name, address, phone }) =>
                    <li key={ id } onClick={ this.openEditor(id) }>{ name } — { address } — { phone }</li> 
                ) }
                </ul>

                <button
                    className="main"
                    onClick={ () => this.setState({ isCreateModalOpen: true }) }
                >Добавить филиал</button>

                <Modal
                    className="affilate-modal"
                    open={ isCreateModalOpen || !isNull(editingAffilateId) }
                    onClose={ this.closeModal }
                >
                    <AffilateForm
                        { ...modalProps }
                        token={ token }
                        onSubmit={ this.closeModal }
                    />
                </Modal>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersPage);